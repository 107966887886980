import React, { useEffect, useLayoutEffect, useState } from "react";
import "./styles/UrModalAddd.scoped.scss";
import AppInput from "../../../layout/AppInput/AppInput";
import AppPhoneInput from "../../../layout/AppPhoneInput/AppPhoneInput";
import AppButton from "../../../layout/AppButton/AppButton";
import AppTextarea from "../../../layout/AppTextarea/AppTextarea";
import EventEmitter from "../../../../utils/EventEmitter";
import { addError } from "../../../../store/reducers/alerts";
import { useDispatch } from "react-redux";
import AppDateInput from "../../../layout/AppDateInput/AppDateInput";
import { setIsClosable } from "../../../../store/reducers/modals";
import AppCheckbox from "../../../layout/AppCheckbox/AppCheckbox";

const IPModalAdd = ({ closeModal, options }) => {
  const dispatch = useDispatch();

  const [actionButtonText] = useState(setActionButtonText());
  const [modalTitle] = useState(setModalTitleData());
  const [bankBikVal, setBankBikVal] = useState(false);
  const [bankAccountVal, setBankAccountVal] = useState(false);
  const [bankNameVal, setBankNameVal] = useState(false);
  const [innVal, setInnVal] = useState(false);
  const [ogrnipVal, setOgrnipVal] = useState(false);
  const [addressVal, setAddressVal] = useState(false);
  const [emailVal, setEmailVal] = useState(false);
  const [phoneVal, setPhoneVal] = useState(false);
  const [birthDateVal, setBirthDateVal] = useState(false);
  const [passportVal, setPassportVal] = useState(false);
  const [ceoFioVal, setCeoFioVal] = useState(false);
  const [loginVal, setLoginVal] = useState(false);
  const [bankKorAccountVal, setBankKorAccountVal] = useState(false);

  const [ip, setIp] = useState({
    id: options?.option?.id,
    username: options?.option?.username,
    type: options?.option?.type || "ip",
    ceo_fio: options?.option?.ceo_fio,
    phone: options?.option?.phone,
    email: options?.option?.email,
    inn: options?.option?.inn,
    bank_name: options?.option?.bank_name,
    bank_bik: options?.option?.bank_bik,
    bank_account: options?.option?.bank_account,
    bank_kor_account: options?.option?.bank_kor_account,
    passport: options?.option?.passport,
    birth_date: options?.option?.birth_date,
    address: options?.option?.address,
    ogrnip: options?.option?.ogrnip,
    active: options?.option?.active,
    meta: "existed",
  });

  useEffect(() => {
    dispatch(setIsClosable(false));
  }, []);

  function setModalTitleData() {
    if (options?.mode === "edit") return "Редактировать ИП";
    else return "Добавить ИП";
  }

  function setActionButtonText() {
    if (options?.mode === "edit") return "Редактировать ИП";
    else return "Добавить ИП";
  }

  const checkValidBankKorAccount = (isValid) => {
    setBankKorAccountVal(isValid);
  };

  const checkValidBankBik = (isValid) => {
    setBankBikVal(isValid);
  };

  const checkValidBankAccount = (isValid) => {
    setBankAccountVal(isValid);
  };

  const checkValidBankName = (isValid) => {
    setBankNameVal(isValid);
  };

  const checkValidInn = (isValid) => {
    setInnVal(isValid);
  };

  const checkValidOgrnip = (isValid) => {
    setOgrnipVal(isValid);
  };

  const checkValidAddress = (isValid) => {
    setAddressVal(isValid);
  };

  const checkValidEmail = (isValid) => {
    setEmailVal(isValid);
  };

  const checkValidPhone = (isValid) => {
    setPhoneVal(isValid);
  };

  const checkValidBirthDate = (isValid) => {
    setBirthDateVal(isValid);
  };

  const checkValidPassport = (isValid) => {
    setPassportVal(isValid);
  };

  const checkValidCeoFio = (isValid) => {
    setCeoFioVal(isValid);
  };

  const checkValidLogin = (isValid) => {
    setLoginVal(isValid);
  };

  const isDisabledActionButton = () => {
    return (
      !emailVal ||
      !bankKorAccountVal ||
      !bankBikVal ||
      !bankAccountVal ||
      !bankNameVal ||
      !ogrnipVal ||
      !addressVal ||
      !innVal ||
      !phoneVal ||
      !ceoFioVal ||
      !birthDateVal ||
      !passportVal
      // !loginVal
    );
  };

  function modalAction() {
    if (options?.mode === "edit")
      validate(() => EventEmitter.emit("editIp", { ...ip, type: "ip" }), ip);
    else
      validate(() => EventEmitter.emit("addNewIp", { ...ip, type: "ip" }), ip);
  }

  function validate(func, data) {
    const errors = [];
    if (!data?.type?.length) errors.push("Не указан тип организации!");
    if (!data?.username?.length) errors.push("Не указан логин!");
    if (!data?.ceo_fio?.length) errors.push("Не указано ФИО руководителя!");
    if (!data?.phone?.length) errors.push("Не указан номер телефона!");
    if (!data?.email?.length) errors.push("Не указан емаил!");
    if (!data?.inn?.length) errors.push("Не указан ИНН!");
    if (!data?.bank_name?.length) errors.push("Не указано название банка!");
    if (!data?.bank_bik?.length) errors.push("Не указан БИК!");
    if (!data?.bank_account?.length) errors.push("Не указан расчетный счет!");
    if (!data?.bank_kor_account?.length)
      errors.push("Не указан корреспондентский счет!");
    if (!data?.passport?.length) errors.push("Не указаны паспортные данные!");
    if (!data?.birth_date?.length) errors.push("Не указана дата рождения!");
    if (!data?.address?.length) errors.push("Не указан адрес!");
    if (!data?.ogrnip?.length) errors.push("Не указан ОГРНИП!");
    if (!errors?.length) {
      func();
    }
    if (errors?.length) {
      for (const error of errors) dispatch(addError(error));
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (typeof options?.option === "object") {
        setIp({
          ...options?.option,
        });
      }
    }, 0);
  }, [options?.option]);

  useEffect(() => {
    setTimeout(() => {
      setIp({
        id: ip?.id,
        username: ip?.username,
        email: ip?.email,
        type: ip?.type,
        ceo_fio: ip?.ceo_fio,
        phone: ip?.phone,
        inn: ip?.inn,
        bank_name: ip?.bank_name,
        bank_bik: ip?.bank_bik,
        bank_account: ip?.bank_account,
        bank_kor_account: ip?.bank_kor_account,
        passport: ip?.passport,
        birth_date: ip?.birth_date,
        address: ip?.address,
        ogrnip: ip?.ogrnip,
        active: ip?.active,
      });
    }, 0);
  }, [
    ip?.address,
    ip?.bank_account,
    ip?.bank_bik,
    ip?.bank_kor_account,
    ip?.bank_name,
    ip?.birth_date,
    ip?.ceo_fio,
    ip?.email,
    ip?.id,
    ip?.inn,
    ip?.ogrnip,
    ip?.passport,
    ip?.phone,
    ip?.type,
    ip?.username,
    ip?.active
  ]);

  return (
    <>
      <h1 className="stats-modal-name">{modalTitle}</h1>
      <div className="modal-wrapper">
        <div className="left-side">
          <h3 className="modal-title">Основная информация</h3>
          <div className="profile-block">
            <div className="input-ur-area">
              <AppInput
                className="modal-input"
                mode="white"
                label="Логин (латинскими буквами, цифрами)"
                placeholder="*****"
                value={ip?.username}
                onEnterActionFunc={() => modalAction()}
                validations={options?.mode === "edit" ? {} : { isEmpty: true }}
                checkValid={checkValidLogin}
                onInput={(e) => setIp({ ...ip, username: e })}
                isReq={true}
                maxLength={150}
                isDisabled={options?.mode === "edit"}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="E-mail"
                placeholder="Указать"
                value={ip?.email}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, isEmail: true }}
                checkValid={checkValidEmail}
                onInput={(e) => setIp({ ...ip, email: e })}
                isReq={true}
                maxLength={253}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="ФИО индивидуального предпринимателя"
                placeholder="Указать"
                value={ip?.ceo_fio}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidCeoFio}
                onInput={(e) => setIp({ ...ip, ceo_fio: e })}
                isReq={true}
              />
              <AppTextarea
                mode="white"
                label="Паспортные данные"
                placeholder="Указать"
                rows={2}
                value={ip?.passport}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidPassport}
                onInput={(e) => setIp({ ...ip, passport: e })}
                isReq={true}
              />
              <AppDateInput
                className="modal-input"
                mode="white"
                label="Дата рождения (дд.мм.гггг)"
                placeholder="Указать"
                value={ip?.birth_date}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, date: true }}
                checkValid={checkValidBirthDate}
                onInput={(e) => setIp({ ...ip, birth_date: e })}
                isReq={true}
              />
              <AppPhoneInput
                className="modal-input"
                mode="white"
                label="Номер телефона"
                placeholder="Указать"
                value={ip?.phone}
                onEnterActionFunc={() => modalAction()}
                checkValid={checkValidPhone}
                validations={{ isEmpty: true, minLength: 18 }}
                onInput={(e) => setIp({ ...ip, phone: e })}
                isReq={true}
              />
              <AppTextarea
                mode="white"
                label="Адрес"
                placeholder="Указать"
                rows={2}
                value={ip?.address}
                onEnterActionFunc={() => modalAction()}
                checkValid={checkValidAddress}
                validations={{ isEmpty: true }}
                onInput={(e) => setIp({ ...ip, address: e })}
                isReq={true}
              />
              <AppCheckbox label="Активно?" onChange={(e) => setIp({ ...ip, active: e })} checked={ip?.active} />
            </div>
          </div>
        </div>
        <div className="left-side">
          <h3 className="modal-title">Бухгалтерская информация</h3>
          <div className="profile-block">
            <div className="input-ur-area">
              <AppInput
                className="modal-input"
                mode="white"
                label="ОГРНИП"
                placeholder="Указать"
                maxLength={15}
                value={ip?.ogrnip}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 15 }}
                checkValid={checkValidOgrnip}
                onInput={(e) => setIp({ ...ip, ogrnip: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="ИНН"
                placeholder="Указать"
                value={ip?.inn}
                maxlength={10}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 10 }}
                checkValid={checkValidInn}
                onInput={(e) => setIp({ ...ip, inn: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Наименование банка"
                placeholder="Указать"
                value={ip?.bank_name}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidBankName}
                onInput={(e) => setIp({ ...ip, bank_name: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="БИК"
                placeholder="Указать"
                value={ip?.bank_bik}
                maxlength={9}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 9, maxLength: 9 }}
                checkValid={checkValidBankBik}
                onInput={(e) => setIp({ ...ip, bank_bik: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Расчётный счёт"
                placeholder="Указать"
                value={ip?.bank_account}
                maxlength={20}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 20 }}
                checkValid={checkValidBankAccount}
                onInput={(e) => setIp({ ...ip, bank_account: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Корреспондентский счёт"
                placeholder="Указать"
                value={ip?.bank_kor_account}
                maxlength={20}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 20 }}
                checkValid={checkValidBankKorAccount}
                onInput={(e) => setIp({ ...ip, bank_kor_account: e })}
                isReq={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-bottom">
        <AppButton
          mode="blue"
          onClick={modalAction}
          isDisabled={isDisabledActionButton()}
        >
          {actionButtonText}
        </AppButton>
        <AppButton onClick={() => closeModal()}>Выйти</AppButton>
      </div>
    </>
  );
};

export default IPModalAdd;
