import { combineReducers, configureStore } from "@reduxjs/toolkit";
import filters from "./reducers/filters";
import tables from "./reducers/tables";
import auth from "./reducers/auth";
import dispatcherProfile from "./reducers/dispatcher/profile";
import dispatcherAccount from "./reducers/dispatcher/account";
import dispatcherCatalog from './reducers/dispatcher/catalog';
import dispatcherOrder from './reducers/dispatcher/order';
import dispatcherPayment from './reducers/dispatcher/payment';
import commonCatalog from './reducers/common/catalog';
import customerAccount from './reducers/customer/account';
import alerts from "./reducers/alerts";
import modals from "./reducers/modals";
import user from './reducers/user';
import test from "./reducers/test";

const rootReducer = combineReducers({
  filters,
  tables,
  auth,
  dispatcherProfile,
  dispatcherAccount,
  dispatcherCatalog,
  dispatcherOrder,
  dispatcherPayment,
  commonCatalog,
  customerAccount,
  alerts,
  modals,
  user,
  test,
});

export default configureStore({
  reducer: rootReducer,
});
