import React from "react";
import "./styles/ChoiseUrModal.scoped.scss";
import { useDispatch } from "react-redux";
import '../../types-of-services/styles/ServAddModal.scoped.scss'
import PropTypes from "prop-types";
import { showModal } from "../../../../store/reducers/modals";
import { modalKeys } from "../../../../utils/utils";
import AppButton from "../../../layout/AppButton/AppButton";

const ChoiceUrModal = ({ options, closeModal }) => {
  const dispatch = useDispatch();

  function moveToUr() {
    dispatch(showModal({ key: modalKeys.urModalAdd }));
  }

  function moveToIP() {
    dispatch(showModal({ key: modalKeys.IPModalAdd }));
  }

  return (
    <>
      <div className="choice-area">
        <h1 className="modal-name">Вид клиента</h1>
        <AppButton
          className="choice-emp"
          mode="blue"
          onClick={() => moveToUr()}
        >
          Юридическое лицо
        </AppButton>
        <AppButton className="choice-operator" onClick={() => moveToIP()}>
          Индивидуальный предприниматель
        </AppButton>
      </div>
    </>
  );
};

ChoiceUrModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

ChoiceUrModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ChoiceUrModal;
