import React, {useEffect, useLayoutEffect, useState} from "react";
import "./styles/FilterModal.scoped.scss";
import AppButton from "../AppButton/AppButton";
import PropTypes from "prop-types";
import { empListFilters, RouterPath } from "../../../utils/utils";
import EventEmitter from "../../../utils/EventEmitter";
import AppSelect from "../AppSelect/AppSelect";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import AppInput from "../AppInput/AppInput";
import {useDispatch, useSelector} from "react-redux";
import {getServicesListAsync} from "../../../store/reducers/common/catalog";

const FilterModal = ({ closeModal, options }) => {
  const currentRoute = window.location.pathname;
  const dispatch = useDispatch();

  const { servicesList } = useSelector((store) => store.commonCatalog);

  const [executorServicesList, setExecutorServicesList] = useState(
      servicesList
  );
  const [executorOrdering, setExecutorOrdering] = useState([
    {
      id: "fio",
      name: "Имени",
    },
    {
      id: "phone",
      name: "Номеру телефона",
    },
    {
      id: "type",
      name: "Типу оказываемых услуг",
    },
  ]);
  const [dispatcherOrdering] = useState([
    {
      id: "fio",
      name: "Имени",
    },
    {
      id: "phone",
      name: "Телефону",
    },
  ]);
  const [executorStatus, setExecutorStatus] = useState([
    { id: "missing", name: "Отсутствует" },
    { id: "working", name: "На смене" },
    { id: "order", name: "На заказе" },
  ]);

  const customerTypes = [
    { id: localStorage.getItem('ip-id'), name: "Физическое лицо" },
    { id: localStorage.getItem('legal-id'), name: "Предприятие" },
  ];

  const months = [
    { id: 1, name: "01" },
    { id: 2, name: "02" },
    { id: 3, name: "03" },
    { id: 4, name: "04" },
    { id: 5, name: "05" },
    { id: 6, name: "06" },
    { id: 7, name: "07" },
    { id: 8, name: "08" },
    { id: 9, name: "09" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
  ];

  const ordering = [
    { id: "id", name: "По Id" },
    { id: "customer_type", name: "По типу заявителя" },
    { id: "customer_name", name: "По названию заявителя" },
    { id: "status", name: "По статусу заявителя" },
    { id: "amount", name: "По сумме" },
    { id: "pay_month", name: "По месяцу" },
    { id: "pay_year", name: "По году" },
  ];

  const [statuses, setStatuses] = useState([
    { id: "waiting", name: "Ожидает оплаты", selected: false },
    { id: "paid", name: "Оплачено", selected: false },
    { id: "overdue", name: "Платеж просрочен", selected: false },
  ]);

  const [executorServicesIds, setExecutorServicesIds] = useState([]);
  const [executorOrderingId, setExecutorOrderingId] = useState(
    JSON.parse(localStorage.getItem("ExecutorOrderingFilter"))?.id
  );
  // const [executorStatusIds, setExecutorStatusIds] = useState([]);
  const [dispatcherOrderingId, setDispatcherOrderingId] = useState([]);
  const [orderingAccountId, setOrderingAccountId] = useState(
    localStorage.getItem("orderingAccountId")
  );
  const [orderingMonth, setOrderingMonth] = useState(
    localStorage.getItem("orderingMonth")
  );
  const [orderingYear, setOrderingYear] = useState(
    localStorage.getItem("orderingYear")
  );
  const [orderingCustomerType, setOrderingCustomerType] = useState(
    localStorage.getItem("orderingCustomerType")
  );
  const [orderingStatusesIds, setOrderingStatusesIds] = useState(
    localStorage.getItem("orderingStatusesIds")
  );

  function setDispatcherOrderingIdData(id) {
    setDispatcherOrderingId(id);
  }

  function setExecutorServices(idx) {
    const arrId = [];
    const arrObj = [];
    let arr = executorServicesList.map((item) => Object.assign({}, item));
    arr[idx].selected = !arr[idx].selected;
    setExecutorServicesList(arr);
    for (const item of arr) {
      if (!!item?.selected) {
        arrObj.push(item);
        arrId.push(item.id);
      } else {
        const indexId = arrId.indexOf(item.id);
        const indexObj = arrObj.indexOf(item);
        if (indexObj !== -1) arrObj.splice(indexObj, 1);
        if (indexId !== -1) arrId.splice(indexId, 1);
      }
    }
    localStorage.setItem("ExecutorServicesFilter", JSON.stringify(arrObj));
    setExecutorServicesIds(arrId);
  }

  function setExecutorOrderingData(id) {
    let arr = executorOrdering.map((item) => Object.assign({}, item));
    const obj = arr.filter((item) => item.id === id)[0];
    const objIndex = arr.indexOf(obj);
    arr[objIndex].selected = !arr[objIndex].selected;
    setExecutorOrdering(arr);
    setExecutorOrderingId(obj.id);
    localStorage.setItem("ExecutorOrderingFilter", JSON.stringify(obj));
  }

  // function setExecutorStatusData(idx) {
  //   const arrId = [];
  //   const arrObj = [];
  //   let arr = executorStatus.map((item) => Object.assign({}, item));
  //   arr[idx].selected = !arr[idx].selected;
  //   setExecutorStatus(arr);
  //   for (const item of arr) {
  //     if (!!item?.selected) {
  //       arrObj.push(item);
  //       arrId.push(item.id);
  //     } else {
  //       const indexObj = arrObj.indexOf(item);
  //       const indexId = arrId.indexOf(item.id);
  //       if (indexObj !== -1) arrObj.splice(indexObj, 1);
  //       if (indexId !== -1) arrId.splice(indexId, 1);
  //     }
  //   }
  //   setExecutorStatusIds(arrId);
  //   localStorage.setItem("ExecutorStatusFilter", JSON.stringify(arrObj));
  // }

  function setOrderingStatusesData(idx) {
    const arrId = [];
    const arrObj = [];
    let arr = statuses.map((item) => Object.assign({}, item));
    arr[idx].selected = !arr[idx].selected;
    setStatuses(arr);
    for (const item of arr) {
      if (!!item?.selected) {
        arrObj.push(item);
        arrId.push(item.id);
      } else {
        const indexId = arrId.indexOf(item.id);
        const indexObj = arrObj.indexOf(item);
        if (indexObj !== -1) arrObj.splice(indexObj, 1);
        if (indexId !== -1) arrId.splice(indexId, 1);
      }
    }
    localStorage.setItem("ExecutorServicesFilter", JSON.stringify(arrObj));
    setOrderingStatusesIds(arrId);
  }

  function sendFilterEvent() {
    if (
      [RouterPath.empList].includes(currentRoute) &&
      localStorage.getItem("empListFilter") === empListFilters.executors
    ) {
      EventEmitter.emit("filterModalEvent", {
        servicesData: executorServicesIds,
        ordering: executorOrderingId,
        // status: executorStatusIds,
      });
    }
    if (
      [RouterPath.empList].includes(currentRoute) &&
      localStorage.getItem("empListFilter") === empListFilters.dispatchers
    ) {
      EventEmitter.emit("filterModalEvent", { ordering: dispatcherOrderingId });
    }
    if ([RouterPath.account].includes(currentRoute)) {
      localStorage.setItem("orderingAccountId", orderingAccountId);
      localStorage.setItem("orderingMonth", orderingMonth);
      localStorage.setItem("orderingYear", orderingYear);
      localStorage.setItem("orderingCustomerType", orderingCustomerType);
      localStorage.setItem("orderingStatusesIds", orderingStatusesIds);
      EventEmitter.emit("filterModalEvent", {
        ordering: orderingAccountId,
        orderingMonth: orderingMonth,
        orderingYear: orderingYear,
        customer_type: orderingCustomerType,
        status: orderingStatusesIds,
      });
    }
    closeModal();
  }

  useLayoutEffect(() => {
    dispatch(getServicesListAsync({page: 1, page_size: 150000 }))
    setExecutorServicesList(servicesList)
  }, [])

  useLayoutEffect(() => {
    // Добавление selected в state массивы при инициализации страницы
    if (!!executorServicesList?.length) {
      let executorServicesArr = executorServicesList.map((item) =>
        Object.assign({}, item, { selected: false })
      );
      setExecutorServicesList(executorServicesArr);
    }
    if (!!executorOrdering?.length) {
      let executorOrderingArr = executorOrdering.map((item) =>
        Object.assign({}, item, { selected: false })
      );
      setExecutorOrdering(executorOrderingArr);
    }
    if (!!executorStatus?.length) {
      let executorStatusArr = executorStatus.map((item) =>
        Object.assign({}, item, { selected: false })
      );
      setExecutorStatus(executorStatusArr);
    }
    setStatuses(statuses)
  }, []);

  useLayoutEffect(() => {
    // Добавление выбранных ранее фильтров при инициализации страницы
    if (currentRoute === RouterPath.empList) {
      if (!!localStorage.getItem("ExecutorServicesFilter")) {
        const arr = JSON.parse(localStorage.getItem("ExecutorServicesFilter"));
        if (!!arr?.length) {
          const prevArr = [...executorServicesList];
          for (const item1 of executorServicesList) {
            for (const item2 of arr) {
              if (item1.id === item2.id) {
                const index = executorServicesList.indexOf(item1);
                prevArr.splice(index, 1, item2);
                setExecutorServicesList(prevArr);
              }
            }
          }
        }
      }
      if (!!localStorage.getItem("ExecutorOrderingFilter")) {
        const arr = JSON.parse(localStorage.getItem("ExecutorOrderingFilter"));
        if (!!arr?.length) {
          const prevArr = [...executorOrdering];
          for (const item1 of executorOrdering) {
            for (const item2 of arr) {
              if (item1.id === item2.id) {
                const index = executorOrdering.indexOf(item1);
                prevArr.splice(index, 1, item2);
                setExecutorOrdering(prevArr);
              }
            }
          }
        }
      }
      if (!!localStorage.getItem("ExecutorStatusFilter")) {
        const arr = JSON.parse(localStorage.getItem("ExecutorStatusFilter"));
        if (!!arr?.length) {
          const prevArr = [...executorStatus];
          for (const item1 of executorStatus) {
            for (const item2 of arr) {
              if (item1.id === item2.id) {
                const index = executorStatus.indexOf(item1);
                prevArr.splice(index, 1, item2);
                setExecutorStatus(prevArr);
              }
            }
          }
        }
      }
    }
  }, []);

  return (
    <>
      <div className="filter-content">
        <h1 className="modal-name">Фильтры</h1>
        <div className="input-area">
          {[RouterPath.empList].includes(currentRoute) &&
            localStorage.getItem("empListFilter") ===
              empListFilters.dispatchers && (
              <>
                <AppSelect
                  className="modal-input"
                  label="Сортировка по"
                  optionsList={dispatcherOrdering}
                  onInput={(e) => setDispatcherOrderingIdData(e)}
                />
              </>
            )}
          {[RouterPath.empList].includes(currentRoute) &&
            localStorage.getItem("empListFilter") ===
              empListFilters.executors && (
              <>
                <div className="filter-wrapper">
                  <div className="spec-variants">
                    <h4 className="area-name">Услуги:</h4>
                    <div className="filter-area">
                      {executorServicesList.map((option, idx) => {
                        return (
                          <div className="spec-area" key={idx}>
                            <div className="spec-item">
                              <AppCheckbox
                                mode="blue"
                                checked={option.selected}
                                onChange={() => setExecutorServices(idx)}
                              />
                              <h3 className="serv-item-name">{option.name}</h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="spec-variants">
                    <h4 className="area-name">Сортировать по:</h4>
                    <div className="filter-area">
                      <AppSelect
                        optionsList={executorOrdering}
                        onInput={setExecutorOrderingData}
                        selected={JSON.parse(
                          localStorage.getItem("ExecutorOrderingFilter")
                        )}
                      />
                    </div>
                  </div>
                  {/*<div className="spec-variants">*/}
                  {/*  <h4 className="area-name">Статус:</h4>*/}
                  {/*  <div className="filter-area">*/}
                  {/*    {executorStatus.map((option, idx) => {*/}
                  {/*      return (*/}
                  {/*        <div className="spec-area" key={idx}>*/}
                  {/*          <div className="spec-item">*/}
                  {/*            <AppCheckbox*/}
                  {/*              mode="blue"*/}
                  {/*              checked={option.selected}*/}
                  {/*              onChange={() => setExecutorStatusData(idx)}*/}
                  {/*            />*/}
                  {/*            <h3 className="serv-item-name">{option.name}</h3>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </>
            )}
          {[RouterPath.account].includes(currentRoute) && (
            <>
              {/*    /!*TODO: Сделать фильтры для аккаунта *!/*/}
              <div className="filter-account-wrapper">
                <h4 className="acc-filter-subname">Сортировать по</h4>
                <div className="filter-status-area">
                  <AppSelect
                    optionsList={ordering}
                    label="Сортировать по"
                    onInput={setOrderingAccountId}
                    selected={
                      ordering.filter((el) => el.id === orderingAccountId)[0]
                    }
                  />
                </div>
                <h4 className="acc-filter-subname">Дата</h4>
                <div className="filter-status-area">
                  <div className="date-filter-item-wrapper">
                    <AppSelect
                      defaultText="Выберите месяц"
                      optionsList={months}
                      selected={months.filter((el) => el.id === orderingMonth)[0]}
                      onInput={setOrderingMonth}
                      label="Месяц"
                    />
                    <AppInput
                      label="Год"
                      placeholder="Введите год"
                      value={orderingYear}
                      onInput={setOrderingYear}
                    />
                  </div>
                </div>
                <h4 className="acc-filter-subname">Статус</h4>
                <div className="filter-status-area">
                  {!!statuses.length &&
                    statuses.map((el, idx) => {
                      return (
                        <div className="filter-status-item">
                          <AppCheckbox
                            mode="blue"
                            label={el?.name}
                            checked={el?.selected}
                            onChange={() => setOrderingStatusesData(idx)}
                          />
                        </div>
                      );
                    })}
                </div>
                <h4 className="acc-filter-subname">Исполнитель</h4>
                <div className="filter-status-area">
                  <AppSelect
                    optionsList={customerTypes}
                    label="Тип исполнителя"
                    onInput={setOrderingCustomerType}
                  />
                </div>
              </div>
            </>
          )}
          <AppButton mode="blue-gradient" onClick={() => sendFilterEvent()}>
            Применить
          </AppButton>
        </div>
      </div>
    </>
  );
};

FilterModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

FilterModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default FilterModal;
