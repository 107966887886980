import React, { useState } from "react";
import ApplicationProcessModalCardMain from "../../../applications/components/applicationProcessModal/components/ApplicationProcessModalCardMain";
import { Mounted } from "../../../../../scripts/hooks";

const MapModal = ({ options, closeModal }) => {
  const [user, setUser] = useState(options?.point);
  Mounted(() => {
    setUser(options.point);
  });

  return (
    <ApplicationProcessModalCardMain
      executor={user}
      isOpened={true}
      isShowStatus={true}
      isShowSelect={false}
      isDisableCloseTasks={true}
    />
  );
};

export default MapModal;
