export const RouterPath = {
  auth: "/",
  empList: "/employers-list",
  apps: "/applications",
  summary: "/summary",
  account: "/finances",
  map: "/map",
  urList: "/ur-list",
  typesOfServices: "/types-of-services",
  areas: "/areas"
};

export const infoPanelButtons = {
  processed: "Ожидает подтверждения",
  inWork: "В работе",
  closed: "Завершено",
  rejected: "Отклонено",
  all: "Все",
};

export const empListFilters = {
  executors: "Исполнители",
  dispatchers: "Диспетчеры",
};
export const urListFilters = {
  urs: "Юр.лица",
  ips: "ИП",
};

export const routerPathModes = {
  auth: "auth",
  empList: "empList",
  apps: "apps",
  summary: "summary",
  account: "account",
  typesOfServ: "typesOfServ",
  map: "map",
  urList: "urList",
  areas: "areas",
  error: "error",
};

export const modalKeys = {
  logoutModal: "logoutModal",
  dispatcherAccModal: "dispatcherAccModal",
  filterModal: "filterModal",
  executorAddModal: "executorAddModal",
  dispatcherAddModal: "dispatcherAddModal",
  payModal: "payModal",
  applicationProcessModal: "applicationProcessModal",
  applicationNewModal: "appNewModal",
  servAddModal: "servAddModal",
  executorAccModal: "executorAccModal",
  choiceServ: "choiceServ",
  choiceModal: "choiceModal",
  choiceUrModal: "choiceUr",
  urModalAdd: "urModal",
  IPModalAdd: "ipModal",
  mapModal: "mapModal",
  RatesModal: 'RatesModal',
};
