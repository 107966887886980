import { createSlice } from "@reduxjs/toolkit";
import { APIUrlPrefix } from "../../utils/constants";
import api from "../api";
import { errorHandler } from "../../scripts/commonScripts";

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {},
});

export const editUserAsync = (data) => async (dispatch) => {
  await api.patch(`${APIUrlPrefix}/user/my/`, data).catch((e) => {
    errorHandler({
      error: e,
      dispatch,
      text: "",
      requestName: "editUserAsync",
    });
  });
};

export default userSlice.reducer;
