import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./styles/AppPaginator.scoped.scss";
import PropTypes from "prop-types";
import SvgSelector from "../SvgSelector/SvgSelector";

const AppPaginator = ({ totalPages, value, pageSetterFunc }) => {
  const [currPage, setCurrPage] = useState(value);

  const backPageBtnStyles = classNames(
    "pager__btn",
    "btn__back",
    backPageBtnModes()
  );
  const nextPageBtnStyles = classNames(
    "pager__btn",
    "btn__next",
    nextPageBtnModes()
  );
  const itemStyles = (item) => classNames("pager__item", itemModes(item));
  const cloudStyles = (item) => classNames("pager__cloud", cloudModes(item));

  const roundedTotalPages = Math.ceil(totalPages);

  const formedPages = () => {
    if (roundedTotalPages <= 7) {
      const array = [];
      for (let i = 1; i <= roundedTotalPages; i += 1) array.push(i);
      return [...array];
    }
    if (roundedTotalPages >= 7) {
      const array = [];
      for (let i = 1; i <= totalPages; i += 1) array.push(i);
      let medium = [];
      if (currPage >= totalPages - 2) {
        medium = array.splice(roundedTotalPages - 5, 4) || [];
        medium = [-1, ...medium];
      } else if (currPage > 4) {
        medium = array.splice(currPage - 2, 3) || [];
        medium = [-1, ...medium, -1];
      } else {
        medium = array.splice(1, 4) || [];
        medium = [...medium, -1];
      }
      return [1, ...medium, roundedTotalPages];
    }
    return roundedTotalPages;
  };

  const setPage = (item) => {
    if (currPage !== item) {
      pageSetterFunc(item);
    }
  };

  const prevPage = () => {
    if (currPage > 1) setPage(currPage - 1);
  };

  const nextPage = () => {
    if (currPage < totalPages) setPage(currPage + 1);
  };

  function backPageBtnModes() {
    if (currPage === 1) return "pager__btn_disabled";
  }

  function nextPageBtnModes() {
    if (currPage === totalPages) return "pager__btn_disabled";
  }

  function itemModes(item) {
    if (currPage === item) return "pager__item_active";
  }

  function cloudModes(item) {
    if (currPage === item) return "pager__cloud_active";
  }

  useEffect(() => {
    setCurrPage(value);
  }, [value, currPage]);

  return (
    <div className="clear">
      {roundedTotalPages > 1 && (
        <div className="pager">
          {!!formedPages()?.length && currPage !== 1 && (
            <button
              className={backPageBtnStyles}
              disabled={currPage === 1}
              onClick={() => prevPage()}
            >
              <SvgSelector className="pager__arrow" id="paginator-arrow" />
            </button>
          )}
          <div className="pager__items">
            {!!formedPages()?.length &&
              formedPages().map((item, idx) => {
                return (
                  <div className={itemStyles(item)} key={idx}>
                    {item === -1 && <div className="pager__dots">...</div>}
                    {item !== -1 && (
                      <button
                        className={cloudStyles(item)}
                        onClick={() => setPage(item)}
                      >
                        {item}
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
          {!!formedPages()?.length && (
            <button
              disabled={currPage === totalPages}
              className={nextPageBtnStyles}
              onClick={() => nextPage()}
            >
              <SvgSelector className="pager__arrow" id="paginator-arrow" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

AppPaginator.defaultProps = {
  totalPages: 0,
  value: 0,
  pageSetterFunc: () => {},
};

AppPaginator.propTypes = {
  totalPages: PropTypes.number,
  value: PropTypes.number,
  pageSetterFunc: PropTypes.func,
};

export default AppPaginator;
