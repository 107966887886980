import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ApplicationProcessModalCardMain from "../../../applicationProcessModal/components/ApplicationProcessModalCardMain";
import "../../../../styles/ApplicationNew.scoped.scss";
import { useDispatch, useSelector } from "react-redux";
import { getExecutorsListAsync } from "../../../../../../../store/reducers/dispatcher/account";
import AppButton from "../../../../../../layout/AppButton/AppButton";
import AppEmpty from "../../../../../../layout/AppEmpty/AppEmpty";
import {AppNewModalContext} from "../../ApplicationNewModal";
import {AppProcessModalContext} from "../../../applicationProcessModal/ApplicationProcessModal";

const ApplicationNewModalExecutorMode = ({mode}) => {
  const {organizationId, setModalView, serviceData, serviceDataId, isShowDDIcon, setExecutorId} = useContext(findContext());
  const dispatch = useDispatch();
  const { executors } = useSelector((state) => state.dispatcherAccount);

  const [filteredExecutors, setFilteredExecutors] = useState([]);
  const [filteredExecutors0, setFilteredExecutors0] = useState([]);
  const [filteredExecutors1, setFilteredExecutors1] = useState([]);
  const [executorsData, setExecutorsData] = useState([]);

  function findContext () {
    return mode === 'new' ? AppNewModalContext : AppProcessModalContext
  }

  function fillExecutors() {
    const arr = [];
    const arr0 = [];
    const arr1 = [];
    const halfLength = Math.ceil(filteredExecutors.length / 2);
    let executorsArr = executors.map((item) =>
      Object.assign({}, item, { selected: false })
    );
    setExecutorsData(executorsArr);
    for (const executor of executors) {
      for (const service of executor?.services) {
        if (!!serviceDataId) {
          if (service?.id === serviceDataId) {
            arr.push(executor);
            setFilteredExecutors(arr);
          }
        }
      }
    }
    for (let i = 0; i < halfLength; i += 1) {
      arr0.push({ ...filteredExecutors[i], arr: 0 });
    }
    for (let i = halfLength; i < filteredExecutors.length; i += 1) {
      arr1.push({ ...filteredExecutors[i], arr: 1 });
    }
    setFilteredExecutors0(arr0);
    setFilteredExecutors1(arr1);
  }

  useEffect(() => {
    dispatch(
      getExecutorsListAsync({
        page_size: 150000,
        page: 1,
        organization: organizationId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getExecutorsListAsync({
        page_size: 150000,
        page: 1,
        organization: organizationId,
      })
    );
  }, [organizationId]);

  useEffect(() => {
    fillExecutors();
  }, [executors, serviceData, serviceDataId]);


  return (
    <>
      <div className="container">
        <h2 className="modal-name">Выбор исполнителя</h2>
        <div className="flex-mode-wrapper">
          <div className="mode-executor__flex-mode">
            {!!filteredExecutors0?.length &&
              filteredExecutors0?.map((executor) => {
                return (
                  <ApplicationProcessModalCardMain
                    filteredExecutors0={filteredExecutors0}
                    filteredExecutors1={filteredExecutors1}
                    setFilteredExecutors0={setFilteredExecutors0}
                    setFilteredExecutors1={setFilteredExecutors1}
                    selectExecutor={setExecutorId}
                    setModalView={setModalView}
                    executor={executor}
                    isShowSelect={true}
                    isShowDDIcon={isShowDDIcon}
                    isShowStatus={true}
                  />
                );
              })}
          </div>
          <div className="mode-executor__flex-mode">
            {!!filteredExecutors1?.length &&
              filteredExecutors1?.map((executor) => {
                return (
                  <ApplicationProcessModalCardMain
                    filteredExecutors0={filteredExecutors0}
                    filteredExecutors1={filteredExecutors1}
                    setFilteredExecutors0={setFilteredExecutors0}
                    setFilteredExecutors1={setFilteredExecutors1}
                    selectExecutor={setExecutorId}
                    setModalView={setModalView}
                    executor={executor}
                    isShowSelect={true}
                    isShowDDIcon={isShowDDIcon}
                    isShowStatus={true}
                  />
                );
              })}
          </div>
        </div>
        {!filteredExecutors0?.length && !filteredExecutors1?.length && (
          <AppEmpty desc="Исполнитель не найден" />
        )}
        <AppButton onClick={() => setModalView("app")}>
          Назад к заявке
        </AppButton>
      </div>
    </>
  );
};

export default ApplicationNewModalExecutorMode;
