import React, {useEffect} from "react";
import "./styles/AppModal.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgSelector from "../SvgSelector/SvgSelector";
import {Mounted} from "../../../scripts/hooks";
import {useSelector} from "react-redux";

const AppModal = ({
  active,
  setActive,
  children,
  width,
  height,
  mode,
  url,
}) => {
  const {isClosable} = useSelector((state) => state.modals)
  const contentStyles = classNames("modal__content", modalStylesModes());
  const modalStyles = classNames("modal", modalStylesModes());
  const crossStyles = classNames("cross", modalStylesModes());
  function modalStylesModes() {
    if (active) return "active";
  }
  const contentWidth = () => {
    const elements = document.getElementsByClassName("modal__content")[0];
    if (elements) {
      for (let i = 0; i < elements.length; i += 1) {
        if (width) elements[i].style.width = width;
        if (height) elements[i].style.height = height;
      }
    }
  }

  Mounted(() => {
    contentWidth();
  })

  useEffect(() => {}, [isClosable])

  return (
    <>
      {mode === "default" && (
        <div className={modalStyles} onClick={isClosable ? () => setActive(false) : () => {}}>
          <div className="modal-wrapper">
            <div className={contentStyles} onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
            {isClosable && (
              <div className={crossStyles} onClick={isClosable ? () => setActive(false) : () => {}}>
                <SvgSelector id="modal-cross" />
              </div>
            )}
          </div>
        </div>
      )}
      {mode === "image" && (
        <div className={modalStyles} onClick={isClosable ? () => setActive(false) : () => {}}>
          <img src={url} alt="" />
        </div>
      )}
    </>
  );
};

AppModal.propTypes = {
  url: PropTypes.string,
  mode: PropTypes.string,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  isClosable: PropTypes.bool,
};

AppModal.defaultProps = {
  url: "",
  mode: "default",
  active: false,
  setActive: () => {},
  children: "",
  width: "50vw",
  height: "95vh",
  isClosable: true,
};

export default AppModal;
