import React, { useEffect, useState } from "react";
import "../../styles/Pay.scoped.scss";
import AppInput from "../../../../layout/AppInput/AppInput";
import AppButton from "../../../../layout/AppButton/AppButton";
import AppPhoneInput from "../../../../layout/AppPhoneInput/AppPhoneInput";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  editDispatcherPaymentBillByIdAsync,
  getDispatcherPaymentBillByIdAsync,
  getDispatcherPaymentBillListAsync,
} from "../../../../../store/reducers/dispatcher/payment";
import AppSelect from "../../../../layout/AppSelect/AppSelect";
import { convertPrice, timeFormat } from "../../../../../scripts/commonScripts";
import { financesPerPage } from "../../../../../utils/constants";
import AppMainTable from "../../../../layout/AppMainTable/AppMainTable";
import AppEmpty from "../../../../layout/AppEmpty/AppEmpty";

const PayModal = ({ closeModal, options }) => {
  const dispatch = useDispatch();
  const { dispatcherPaymentBill } = useSelector(
    (state) => state.dispatcherPayment
  );
  const { accountTableModal, accountTableModalDocs } = useSelector(
    (state) => state.tables
  );
  const [billId, setBillId] = useState(options.option?.id);
  const [amount, setAmount] = useState(dispatcherPaymentBill?.amount);
  const [ceoFio, setCeoFio] = useState(
    dispatcherPaymentBill?.customer?.ceo_fio
  );
  const [phone, setPhone] = useState(dispatcherPaymentBill?.customer?.phone);
  const [email, setEmail] = useState(dispatcherPaymentBill?.customer?.email);
  const [customerNumber, setCustomerNumber] = useState(
    dispatcherPaymentBill?.customer?.user_id
  );
  const [shortName, setShortName] = useState(
    dispatcherPaymentBill?.customer?.short_name
  );
  const [customerType, setCustomerType] = useState(
    dispatcherPaymentBill?.customer_type
  );
  const [paidAt, setPaidAt] = useState(dispatcherPaymentBill?.paid_at);
  const [statusData, setStatusData] = useState();
  const [orders, setOrders] = useState(dispatcherPaymentBill?.orders);
  const [docs, setDocs] = useState([]);
  const [idIP] = useState(localStorage.getItem("ip-id"));
  const [idLegal] = useState(localStorage.getItem("legal-id"));

  const customerTypes = [
    { id: idIP, name: "Физическое лицо" },
    { id: idLegal, name: "Предприятие" },
  ];

  const [statuses] = useState([
    { id: "waiting", name: "Ожидает оплаты" },
    { id: "paid", name: "Оплачено" },
    { id: "overdue", name: "Платеж просрочен" },
  ]);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  function findCustomerTypeInArr(id) {
    return customerTypes.filter((el) => +el.id === id)[0];
  }

  function findStatusInArr(id) {
    return statuses.filter((el) => +el.id === id)[0];
  }

  useEffect(() => {
    setBillId(options.option?.id);
  }, [options]);

  useEffect(() => {
    dispatch(getDispatcherPaymentBillByIdAsync(billId));
  }, [billId]);

  useEffect(() => {
    if (!!dispatcherPaymentBill?.orders?.length)
      setOrders(dispatcherPaymentBill?.orders);

    setAmount(convertPrice(dispatcherPaymentBill?.amount, "front"));
    setCeoFio(dispatcherPaymentBill?.customer?.ceo_fio);
    setPhone(dispatcherPaymentBill?.customer?.phone);
    setEmail(dispatcherPaymentBill?.customer?.email);
    setShortName(dispatcherPaymentBill?.customer?.short_name);
    setCustomerNumber(dispatcherPaymentBill?.customer?.user_id);
    setCustomerType(dispatcherPaymentBill?.customer_type);
    setPaidAt(dispatcherPaymentBill?.paid_at);
    setStatusData(dispatcherPaymentBill?.status);

    if (!!dispatcherPaymentBill?.bill_documents?.length) {
      const arrBill = dispatcherPaymentBill?.bill_documents
        .filter((el) => el.type === "bill")
        .sort(byField("from_customer"));
      const arrCertificate = dispatcherPaymentBill?.bill_documents
        .filter((el) => el.type === "certificate")
        .sort(byField("from_customer"));
      let bill = {};
      let certificate = {};
      if (
        localStorage.getItem("accountTabCustomerTypeId") ===
        localStorage.getItem("legal-id")
      ) {
        for (let i = 0; i < arrBill?.length; i += 1) {
          bill = {
            type: arrBill[0]?.type,
            file0: arrBill[0]?.file,
            file1: arrBill[1]?.file,
          };
        }
        for (let i = 0; i < arrCertificate?.length; i += 1) {
          certificate = {
            type: arrCertificate[0]?.type,
            file0: arrCertificate[0]?.file,
            file1: arrCertificate[1]?.file,
          };
        }
        setDocs([bill, certificate]);
      } else {
        setDocs(
          dispatcherPaymentBill?.bill_documents.filter(
            (el) => el.type === "certificate"
          )
        );
      }
    }
  }, [dispatcherPaymentBill]);

  return (
    <>
      <div className="container">
        <h2 className="modal-pay-name">Финансы</h2>
        <div className="person-pay-info">
          <AppSelect
            optionsList={customerTypes}
            onInput={setCustomerType}
            selected={findCustomerTypeInArr(customerType)}
            label="Тип заявителя"
            defaultText="Выберите статус"
            mode="white"
            isDisabled={true}
          />
          {!!shortName?.length && (
            <AppInput
              label="Заявитель"
              placeholder="Введите название"
              mode="white"
              validations={{ isEmpty: true }}
              value={shortName}
              onInput={setShortName}
              isDisabled={true}
            />
          )}
          <AppInput
            label="№ плательщика"
            placeholder="Номер плательщика"
            mode="white"
            validations={{ isEmpty: true }}
            isDisabled={true}
            value={customerNumber}
            onInput={setCustomerNumber}
          />
          <AppInput
            label="ФИО"
            placeholder="Введите данные"
            validations={{ isEmpty: true }}
            rows={2}
            mode="white"
            value={ceoFio}
            onInput={setCeoFio}
            isDisabled={true}
          />
          <AppPhoneInput
            label="Телефон"
            placeholder="+7 (###) ###-##-##"
            validations={{ isEmpty: true, minLength: 18 }}
            value={phone}
            onInput={setPhone}
            mode="white"
            isDisabled={true}
          />
          <AppInput
            label="Email"
            placeholder="Введите email"
            mode="white"
            validations={{ isEmpty: true }}
            value={email}
            onInput={setEmail}
            isDisabled={true}
          />
          <AppInput
            className="app-date-input-year"
            label="Срок оплаты"
            placeholder="Не оплачено"
            mode="white"
            value={paidAt ? timeFormat(paidAt) : ""}
            onInput={setPaidAt}
            validations={{ isEmpty: true }}
            isDisabled={true}
          />
          <AppInput
            label="Общая стоимость (₽)"
            placeholder="₽"
            mode="white"
            value={amount}
            onInput={setAmount}
            validations={{ isEmpty: true }}
            isDisabled={true}
          />
        </div>
        <span className="bottom-line"></span>
        {!!dispatcherPaymentBill?.orders?.length && (
          <>
            <h3 className="side-name">Заявки</h3>
            <AppMainTable
              headerList={accountTableModal}
              isShowSearchBarTop={false}
              isShowSearch={false}
              itemList={orders}
              mode="accountPay"
            />
            <h3 className="side-name">Документы</h3>
            <AppMainTable
              headerList={accountTableModalDocs}
              isShowSearchBarTop={false}
              isShowSearch={false}
              isHeadCenter={true}
              itemList={docs}
              mode="accountOrdersDocs"
            />
          </>
        )}
        {!dispatcherPaymentBill?.orders?.length && (
          <div className="modal-pay__empty">
            <AppEmpty desc="Нет данных об оплате" />
          </div>
        )}
        <div className="control-area">
          <div className="select-wrapper">
            <AppSelect
              optionsList={statuses}
              placeholder="Указать статус"
              mode="white"
              selected={findStatusInArr(statusData)}
              onInput={setStatusData}
              isDisabled={
                localStorage.getItem("accountTabCustomerTypeId") ===
                localStorage.getItem("ip-id")
              }
            />
          </div>
          {localStorage.getItem("accountTabCustomerTypeId") ===
            localStorage.getItem("legal-id") && (
            <AppButton
              mode="blue"
              onClick={() => {
                dispatch(
                  editDispatcherPaymentBillByIdAsync(billId, {
                    status: statusData,
                    amount: amount,
                  })
                ).then(() => {
                  dispatch(
                    getDispatcherPaymentBillListAsync({
                      page: 1,
                      page_size: financesPerPage,
                      customer_type: localStorage.getItem(
                        "accountTabCustomerTypeId"
                      ),
                    })
                  );
                });
                setTimeout(() => {
                  closeModal();
                }, 300);
              }}
            >
              Сохранить
            </AppButton>
          )}
          <AppButton
            onClick={() => {
              dispatch(
                getDispatcherPaymentBillListAsync({
                  page: 1,
                  page_size: financesPerPage,
                  customer_type: localStorage.getItem(
                    "accountTabCustomerTypeId"
                  ),
                })
              );
              closeModal();
            }}
          >
            Закрыть
          </AppButton>
        </div>
      </div>
    </>
  );
};

PayModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

PayModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default PayModal;
