import React, { useEffect, useState } from "react";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { RouterPath, urListFilters } from "../../../utils/utils";
import { Mounted } from "../../../scripts/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompanyCustomerAsync,
  editCompanyCustomerByIdAsync,
  getCompanyCustomerListAsync,
} from "../../../store/reducers/dispatcher/account";
import EventEmitter from "../../../utils/EventEmitter";
import {updatePagesPeriod, urListPerPage} from "../../../utils/constants";

const UrList = () => {
  const currLocation = window.location.pathname;
  const dispatch = useDispatch();
  const urListHeaders = useSelector((state) => state.tables.urListHeaders);
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState(1);

  const [urListFilter, setUrListFilter] = useState(
    localStorage.getItem("urListFilter")
  );
  const { companyCustomerList, companyCustomerListCount } = useSelector(
    (store) => store.dispatcherAccount
  );
  const [companyCustomerListData, setCompanyCustomerList] =
    useState(companyCustomerList);

  const getCompanyCustomerList = (params) => {
    dispatch(
      getCompanyCustomerListAsync({
        q: search,
        page: 1,
        page_size: urListPerPage,
        type:
          localStorage.getItem("urListFilter") === urListFilters.urs
            ? "legal"
            : "ip",
        ...params,
      })
    );
  };

  function getData(page) {
    if (localStorage.getItem("urListFilter") === urListFilters.urs) {
      getCompanyCustomerList({
        type: "legal",
        page: page || pageData,
        page_size: urListPerPage,
      });
    } else {
      getCompanyCustomerList({
        type: "ip",
        page: page || pageData,
        page_size: urListPerPage,
      });
    }
  }

  Mounted(() => {
    if (!localStorage.getItem("urListFilter")) {
      localStorage.setItem("urListFilter", urListFilters.ips);
    } else {
      setInterval(() => {
        setUrListFilter(localStorage.getItem("urListFilter"));
      }, 300);
    }
  });

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const updatePage = setInterval(() => {
      if (currLocation === RouterPath.urList) getData();
    }, updatePagesPeriod);
    return () => clearInterval(updatePage);
  }, []);

  useEffect(() => {
    const addNewIpListener = EventEmitter.addListener("addNewIp", (q) => {
      dispatch(createCompanyCustomerAsync(q)).then(() => getData());
    });
    const editIpListener = EventEmitter.addListener("editIp", (q) => {
      dispatch(editCompanyCustomerByIdAsync(q.id, q)).then(() => getData());
    });
    const editUrListener = EventEmitter.addListener("editUr", (q) => {
      dispatch(editCompanyCustomerByIdAsync(q.id, q)).then(() => getData());
    });
    const addNewUrListener = EventEmitter.addListener("addNewUr", (q) => {
      dispatch(createCompanyCustomerAsync(q)).then(() => getData());
    });
    const searchListener = EventEmitter.addListener("search", (q) => {
      if (currLocation === RouterPath.urList) {
        if (
          [urListFilters.urs, urListFilters.ips].includes(
            localStorage.getItem("urListFilter")
          )
        ) {
          if (q.length > 2) setSearch(q);
          else if (!!q.length) setSearch("");
          getData();
        }
      }
    });
    const urListFiltersTypeListener = EventEmitter.addListener(
      "urListFiltersType",
      (params) => {
        if (currLocation === RouterPath.urList) {
          setSearch("");
        }
      }
    );
    const pageListener = EventEmitter.addListener("page", (page) => {
      setPageData(page);
      getData(page);
    });
    return () => {
      addNewIpListener.remove();
      editIpListener.remove();
      addNewUrListener.remove();
      editUrListener.remove();
      searchListener.remove();
      urListFiltersTypeListener.remove();
      pageListener.remove();
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setCompanyCustomerList(companyCustomerList);
    }, 0);
  }, [companyCustomerList]);

  return (
    <>
      <AppMainTable
        title={
          urListFilter === urListFilters.urs ? "Список Юр.лиц" : "Список ИП"
        }
        search={
          urListFilter === urListFilters.urs ? "Найти Юр.лицо" : "Найти ИП"
        }
        itemList={companyCustomerListData}
        itemListTotalCount={companyCustomerListCount}
        headerList={urListHeaders}
        mode="urListTable"
        perPage={urListPerPage}
      />
    </>
  );
};

export default UrList;
