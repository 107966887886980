import React from "react";
import "../styles/Choice.scss";
import AppButton from "../../../layout/AppButton/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../store/reducers/modals";
import { modalKeys } from "../../../../utils/utils";
import PropTypes from "prop-types";
import { dispatcherProfile } from "../../../../store/reducers/dispatcher/profile";

const ChoiceModal = ({ options, closeModal }) => {
  const dispatch = useDispatch();
  const profile = useSelector(dispatcherProfile);

  function moveToExecutor(option, idx) {
    dispatch(
      showModal({
        key: modalKeys.executorAddModal,
        option: options.option,
        idx: options.idx,
        services: options.services,
      })
    );
  }

  function moveToDispatcher() {
    dispatch(showModal({ key: modalKeys.dispatcherAddModal }));
  }

  return (
    <>
      <div className="choice-area">
        <h1 className="modal-name">Вид сотрудника</h1>
        <AppButton
          className="choice-emp"
          mode="blue"
          onClick={() => moveToExecutor()}
        >
          Исполнитель
        </AppButton>
        {profile?.is_admin && (
          <AppButton
            className="choice-operator"
            onClick={() => moveToDispatcher()}
          >
            Диспетчер
          </AppButton>
        )}
      </div>
    </>
  );
};

ChoiceModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

ChoiceModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ChoiceModal;
