import React, {useState} from "react";
import AppButton from "../AppButton/AppButton";
import PropTypes from "prop-types";

const AppUploader = ({ data, setterFunc, setFilePreviewFunc }) => {

    // TODO: Добавить проверки: Валидация фото при создании

    const hiddenFileInput = React.useRef(null);
    const [filePreview, setFilePreview] = useState("");

    function openUploader() {
        hiddenFileInput.current.click();
    }

    function photoPreview(event) {
        const reader = new FileReader();
        reader.onloadend = function () {
            setFilePreview(reader.result);
            setFilePreviewFunc(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    function handleChange(event) {
        setterFunc({ ...data, photo: event.target.files[0] });
        photoPreview(event);
    }

  return (
    <>
      <div className="profile-block">
        <div className="info-block">
          <img
            className="profile-block__avatar"
            alt=""
            src={
              filePreview ||
              data?.photo ||
              require("../../images/logo512.png")
            }
          />
          <div className="profile-avatar__info">
            <h3 className="profile-title">Фото профиля</h3>
            <h4 className="info">Формат файла: jpg/jpeg</h4>
            <h4 className="info">Разрешение: 220x220 - 900x900 px</h4>
            <h4 className="info">Размер файла: до 10 мб</h4>
          </div>
        </div>
        <input
          id="uploader"
          ref={hiddenFileInput}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
        <AppButton mode="blue-gradient" onClick={openUploader}>
          Загрузить изображение
        </AppButton>
      </div>
    </>
  );
};

AppUploader.defaultProp = {
    data: {},
    setterFunc: () => {},
    setFilePreviewFunc: () => {}
}

AppUploader.propTypes = {
    data: PropTypes.object,
    setterFunc: PropTypes.func,
    setFilePreviewFunc: PropTypes.func
}

export default AppUploader;
