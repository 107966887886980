import React from "react";
import "../styles/ApplicationProcessModalCardTask.scoped.scss";
import PropTypes from "prop-types";
import {
  statusFormat,
  timesName,
} from "../../../../../../scripts/commonScripts";
import classNames from "classnames";

const ApplicationProcessModalCardTask = ({ task }) => {
    const taskStyles = classNames('card-emp-task', taskStylesModes());
    function taskStylesModes() {
        if (task?.status === 'in_progress') return 'card-emp-task__in-progress'
    }
  return (
    <div className={taskStyles}>
      <div className="card-emp-task__cols2 cols2">
        <div className="cols2__number">№{task?.id}</div>
        <div className="cols2__number">{task?.service?.name}</div>
        <div className="cols2__number">{statusFormat(task?.status)}</div>
      </div>
      <div className="card-emp-task__subtitle">
        Дата: {task?.date}, {timesName(task?.time)}
      </div>
    </div>
  );
};

ApplicationProcessModalCardTask.defaultProps = {};
ApplicationProcessModalCardTask.propTypes = {
  task: PropTypes.object,
};

export default ApplicationProcessModalCardTask;
