import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles/NavbarBtn.scss'
import { NavLink } from "react-router-dom";

const NavbarBtn = ({ to, children, index }) => {
    const classes = classNames('navbar-btn')
    function clickOnLink () {
        const element = document.getElementsByClassName('navbar-btn')
        element[index].click()
    }
    return (
        <div className="navbar-btn__container" onClick={ clickOnLink } >
            <NavLink className={ classes } to={ to }>{ children }</NavLink>
            <div className="gradient2"/>
        </div>
    )
}

NavbarBtn.defaultProps = {
    to: '',
    children: ''
}

NavbarBtn.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default NavbarBtn
