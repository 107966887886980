import React from "react";
import SvgSelector from "../../../../../layout/SvgSelector/SvgSelector";

const ApplicationProcessModalPaymentIndication = ({ paid }) => {
  return (
    <div className="indicator-app-area">
      {paid && (
        <>
          <SvgSelector id="indication-true" />
          <h3 className="discount-label">Онлайн оплата прошла успешно</h3>
        </>
      )}
      {!paid && (
        <>
          <SvgSelector id="indication-false" />
          <h3 className="discount-label">Не оплачено</h3>
        </>
      )}
    </div>
  );
};

export default ApplicationProcessModalPaymentIndication;
