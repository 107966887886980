import React, {useEffect, useLayoutEffect} from "react";
import "../../styles/App.scss";
import "../../styles/reset.css";
import Navbar from "../Navbar/Navbar";
import classNames from "classnames";
import AppAlert from "../AppAlert/AppAlert";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../../store/reducers/modals";
import AppModal from "../AppModal/AppModal";
import { checkRoute, modalRender } from "../../../scripts/layout";
import { getDictionariesAsync } from "../../../store/reducers/common/catalog";
import { RouterPath } from "../../../utils/utils";
import OneSignal from "react-onesignal";
import {editUserAsync} from "../../../store/reducers/user";

const AppLayout = ({ mode, isShowNavbar }) => {
  const currentPath = window.location.pathname;
  const dispatch = useDispatch();
  const layoutStyle = classNames("app", checkIsShowNavbar());
  const { isShow, currentModalKey, options } = useSelector(
    (state) => state.modals
  );

  function checkIsShowNavbar() {
    if (isShowNavbar) return "two__cols";
    else return "";
  }

  function closeModal() {
    dispatch(hideModal());
  }

  useLayoutEffect(() => {
    if (currentPath !== RouterPath.auth) {
      dispatch(getDictionariesAsync());
    }
  }, [])

  useEffect(() => {
    if (!window.OneSignal) {
      window.OneSignal = window.OneSignal || [];
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        autoRegister: true,
      }).then(() => {
        OneSignal.showSlidedownPrompt().then(() => {
          OneSignal.on("subscriptionChange", function (isSubscribed) {
            console.log("The user's subscription state is now:", isSubscribed);
          });
        });
      });
    }
    OneSignal.getUserId((userId) => {
      console.log("player_id of the subscribed user is : " + userId);
      if (!!userId) dispatch(editUserAsync({ one_signal_id: userId }))
    });
  }, []);

  return (
    <>
      <AppAlert />
      <div className={layoutStyle}>
        {isShowNavbar && <Navbar />}
        {checkRoute(mode)}
        <AppModal
          active={isShow}
          setActive={closeModal}
          isClosable={options?.isClosable}
        >
          {modalRender(options, closeModal, currentModalKey)}
        </AppModal>
      </div>
    </>
  );
};

export default AppLayout;
