import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../styles/PersonAccModal.scss";
import AppInput from "../../../layout/AppInput/AppInput";
import AppSelect from "../../../layout/AppSelect/AppSelect";
import AppPhoneInput from "../../../layout/AppPhoneInput/AppPhoneInput";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { servicesSearchById } from "../../../../scripts/table";
import AppUploader from "../../../layout/AppUploader/AppUploader";
import { addError } from "../../../../store/reducers/alerts";
import EventEmitter from "../../../../utils/EventEmitter";
import AppButton from "../../../layout/AppButton/AppButton";
import AppCheckbox from "../../../layout/AppCheckbox/AppCheckbox";
import { statusFormat, timesName } from "../../../../scripts/commonScripts";
import { getDispatcherOrderListAsync } from "../../../../store/reducers/dispatcher/order";
import AppEmpty from "../../../layout/AppEmpty/AppEmpty";
import {
  getDistrictListAsync,
} from "../../../../store/reducers/common/catalog";
import { getExecutorByIdAsync } from "../../../../store/reducers/dispatcher/account";

const ExecutorAccModal = ({ closeModal, options }) => {
  const topModal = useRef(null);
  const statsAreaTop = useRef(null);
  const dispatch = useDispatch();
  const { dispatcherOrderList } = useSelector((state) => state.dispatcherOrder);
  const { organizations } = useSelector((state) => state.dispatcherCatalog);
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);
  const { executor } = useSelector((store) => store.dispatcherAccount);
  const { servicesList, districtList } = useSelector(
    (store) => store.commonCatalog
  );
  const [dispatcherOrderListData, setDispatcherOrderListData] =
    useState(dispatcherOrderList);
  const [organizationsData, setOrganizationsData] = useState(organizations);
  const [executorServicesArr, setExecutorServicesArr] = useState("");

  const [servicesListData, setServicesListData] = useState(servicesList);

  const [filePreviewData, setFilePreviewData] = useState("");
  const [isFioVal, setFioVal] = useState(false);
  const [isTypeVal, setTypeVal] = useState(false);
  const [isPhoneVal, setPhoneVal] = useState(false);
  const [isEmailVal, setEmailVal] = useState(false);
  const [isAddressVal, setAddressVal] = useState(false);
  const [statusData, setStatusData] = useState("");
  const [orderingData, setOrderingData] = useState("");
  const [districtListData, setDistrictListData] = useState(districtList);
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin'));

  const status = [
    { id: "", name: "Все" },
    { id: "created", name: "Создано" },
    { id: "canceled", name: "Отклонено" },
    { id: "wait_approve", name: "Обработка" },
    { id: "approved", name: "Подтверждено диспетчером" },
    { id: "in_progress", name: "В работе" },
    { id: "work_is_done", name: "Работа выполнена" },
    { id: "wait_payment", name: "Ожидает оплаты" },
    { id: "finished", name: "Завершено" },
  ];

  const ordering = [
    { id: "", name: "Без сортировки" },
    { id: "id", name: "По номеру" },
    { id: "created_at", name: "По дате создания" },
    { id: "updated_at", name: "По дате обновления" },
    { id: "service", name: "По услуге" },
    { id: "service__type", name: "По типу услуги" },
    { id: "status", name: "По статусу" },
    { id: "date", name: "По дате" },
    { id: "time", name: "По времени" },
  ];

  const types = [
    { id: "water", name: "Водовоз" },
    { id: "waste", name: "Ассенизатор" },
    { id: "crane", name: "Кран" },
    { id: "excavator", name: "Экскаватор" },
    { id: "boer", name: "Буровая" },
    { id: "bulldozer", name: "Бульдозер" },
    { id: "loader", name: "Погрузчик" },
    { id: "tipper", name: "Самосвал" },
    { id: "tractor", name: "Тягач" },
    { id: "oil", name: "Нефтевоз" },
  ];

  const [emp, setEmp] = useState({
    id: options?.option?.id,
    fio: options?.option?.fio,
    code: null,
    services: options?.option?.services,
    phone: options?.option?.phone,
    type: options?.option?.type,
    email: options?.option?.email,
    photo: options?.option?.photo,
    address: options?.option?.address,
    organization: options?.option?.organization,
    district: options?.option?.district?.id,
    meta: "existed",
  });
  function setPhoto(eventData) {
    setEmp(eventData);
  }
  function setFilePreview(eventData) {
    setFilePreviewData(eventData);
  }
  function validate(func, data) {
    const errors = [];
    if (!data?.fio?.length) errors.push("Не указано имя исполнителя");
    if (!data?.email?.length) errors.push("Не указан email исполнителя");
    if (!data?.phone?.length) errors.push("Не указан телефон исполнителя");
    if (!data?.services?.length) errors.push("Не указаны услуги исполнителя");
    if (!data?.photo) errors.push("Не добавлено фото исполнителя");
    if (!data?.address?.length) errors.push("Не указан адрес исполнителя");
    if (!data?.district?.toString().length) errors.push("Не указан район");
    if (!errors?.length) {
      func();
    }
    if (errors?.length) {
      for (const error of errors) dispatch(addError(error));
    }
  }

  function editExecutor() {
    EventEmitter.emit("editExecutor", {
      ...emp,
      filePreview: filePreviewData,
      services: executorServices(),
    });
  }
  function modalAction() {
    validate(editExecutor, emp);
  }
  function searchOrg(id) {
    if (id) return organizationsData.find((el) => el.id === id);
  }
  const checkValidFio = (isValid) => {
    setFioVal(isValid);
  };
  const checkValidType = (isValid) => {
    setTypeVal(isValid);
  };

  const checkValidPhone = (isValid) => {
    setPhoneVal(isValid);
  };

  const checkValidEmail = (isValid) => {
    setEmailVal(isValid);
  };

  const checkValidAddress = (isValid) => {
    setAddressVal(isValid);
  };

  const isDisabledActionButton = () => {
    return !isFioVal || !isPhoneVal || !isEmailVal || !isAddressVal;
  };

  function setSelectedSpec(idx) {
    let prevArr = servicesListData.map((item) => Object.assign({}, item));
    prevArr[idx].selected = !prevArr[idx].selected;
    setServicesListData(prevArr);
  }

  function executorServices() {
    if (!!servicesListData?.length) {
      const arrObj = servicesListData.filter((item) => item.selected);
      const arrIds = [];
      for (const item of arrObj) arrIds.push(item.id);
      return arrIds;
    }
  }
  function setStatusDataId(id) {
    setStatusData(id);
    dispatch(
      getDispatcherOrderListAsync({
        page: 1,
        page_size: 150000,
        executor: emp?.id,
        status: id,
        ordering: orderingData,
      })
    );
  }

  function setOrderingId(id) {
    setOrderingData(id);
    dispatch(
      getDispatcherOrderListAsync({
        page: 1,
        page_size: 150000,
        executor: emp?.id,
        status: statusData,
        ordering: id,
      })
    );
  }

  useLayoutEffect(() => {
    servicesSearchById({
      services: emp?.services,
      servicesList: servicesListData,
      setterFunc: (e) => {
        const arr = [];
        for (const service of e) {
          arr.push(service?.name);
        }
        const string = arr.join(", ");
        setExecutorServicesArr(string);
      },
    });
  }, [emp?.services, servicesList, servicesListData]);

  useEffect(() => {
    if (typeof options?.option === "object") {
      dispatch(getExecutorByIdAsync(options?.option?.id));
      setEmp({
        id: options?.option?.id,
        idx: options?.option?.idx,
        fio: options?.option?.fio,
        code: executor?.code,
        services: options?.option?.services,
        phone: options?.option?.phone,
        type: options?.option?.type,
        email: options?.option?.email,
        photo: options?.option?.photo,
        address: options?.option?.address,
        organization: options?.option?.organization,
        district: options?.option?.district?.id,
        meta: "existed",
        active: true,
      });
    }
    if (dispatcherProfile?.is_admin) {
      setIsAdmin(dispatcherProfile?.is_admin);
    }
  }, []);

  useEffect(() => {
    setEmp({ ...emp, code: executor?.code })
  }, [executor?.code])

  useEffect(() => {
    setTimeout(() => {
      setEmp({
        id: emp.id,
        idx: emp?.idx,
        fio: emp.fio,
        services: emp.services,
        type: emp.type,
        phone: emp.phone,
        email: emp.email,
        photo: emp.photo,
        address: emp.address,
        district: emp.district,
        organization: emp?.organization,
        meta: emp.meta,
        active: true,
      });
    }, 0);
  }, [
    emp.id,
    emp.fio,
    emp.services,
    emp.type,
    emp.phone,
    emp.email,
    emp.photo,
    emp.address,
    emp.district,
    emp.meta,
    emp?.option?.organization,
    emp?.idx,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setDispatcherOrderListData(dispatcherOrderList);
    }, 0);
  }, [dispatcherOrderList]);

  useEffect(() => {
    setTimeout(() => {
      setOrganizationsData(organizations);
    }, 0);
  }, [organizations]);

  useEffect(() => {
    setDistrictListData(districtList);
  }, [districtList]);

  useLayoutEffect(() => {
    dispatch(getDistrictListAsync({ page: 1, page_size: 150000 }));
    dispatch(
      getDispatcherOrderListAsync({
        page: 1,
        page_size: 150000,
        executor: emp?.id,
        status: statusData,
        ordering: orderingData,
      })
    );
    if (!!servicesListData?.length) {
      let servicesListWithSelectedArr = servicesListData.map((item) =>
        // Добавление selected ключа к неизменяемому объекту, копирование
        Object.assign({}, item, { selected: false })
      );
      // сохранение в state
      setServicesListData(servicesListWithSelectedArr);
      const userServicesArr = [];
      for (const item of emp?.services) {
        // преобразование чисел в объекты по id
        const userService = servicesListWithSelectedArr.filter((e) => e?.id === item.id)[0];
        if (typeof userService !== "undefined") {
          userServicesArr.push(userService);
        }
      }

      servicesListWithSelectedArr.forEach((item) => {
        userServicesArr.forEach((item2) => {
          if (item.id === item2.id) {
            item.selected = true;
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    setDistrictListData(districtList);
    setOrganizationsData(organizations);
    setDispatcherOrderListData(dispatcherOrderList);
  }, []);

  function findInTypeList(type) {
    return types.filter((e) => e.id === type)[0];
  }

  function searchDis(id) {
    if (!!id) return districtListData.find((el) => el.id === id);
  }

  return (
    <>
      <div className="modal-wrapper" ref={topModal}>
        <div className="left-side">
          <div className="personContent">
            <h1 className="stats-modal-name">Профиль исполнителя</h1>
            <div className="profile-main-block">
              <div className="info-block">
                <div className="add-emp-wrapper">
                  <AppUploader
                    data={emp}
                    setterFunc={setPhoto}
                    setFilePreviewFunc={setFilePreview}
                  />
                </div>
              </div>
              <div className="input-exec-area">
                <AppInput
                  className="modal-input"
                  mode="white"
                  label="ФИО"
                  value={emp?.fio}
                  placeholder={emp?.fio}
                  validations={{ isEmpty: true }}
                  checkValid={checkValidFio}
                  onEnterActionFunc={() => modalAction()}
                  onInput={(e) => setEmp({ ...emp, fio: e })}
                  isReq={true}
                />
                <AppInput
                  className="modal-input"
                  mode="white"
                  label="Код входа в приложение"
                  value={emp?.code}
                  placeholder="Пользователю нужно авторизоваться"
                  isDisabled={true}
                />
                <AppSelect
                  className="modal-input"
                  mode="white"
                  label="Тип"
                  optionsList={types}
                  selected={findInTypeList(emp?.type)}
                  validations={{ isEmpty: true }}
                  checkValid={checkValidType}
                  onEnterActionFunc={() => modalAction()}
                  onInput={(e) => setEmp({ ...emp, type: e })}
                  isReq={true}
                />
                <AppInput
                  className="modal-input"
                  mode="white"
                  label="E-mail"
                  placeholder="Не заполнено"
                  value={emp?.email}
                  validations={{ isEmpty: true, isEmail: true }}
                  checkValid={checkValidEmail}
                  onEnterActionFunc={() => modalAction()}
                  onInput={(e) => setEmp({ ...emp, email: e })}
                  isReq={true}
                />
                <AppPhoneInput
                  className="modal-input"
                  mode="white"
                  label="Телефон"
                  validations={{ isEmpty: true, minLength: 12 }}
                  placeholder={emp?.phone}
                  value={emp?.phone}
                  checkValid={checkValidPhone}
                  onEnterActionFunc={() => modalAction()}
                  onInput={(e) => setEmp({ ...emp, phone: e })}
                  isReq={true}
                />
                <AppInput
                  className="modal-input"
                  mode="white"
                  label="Адрес проживания"
                  validations={{ isEmpty: true }}
                  placeholder={emp?.address}
                  value={emp?.address}
                  checkValid={checkValidAddress}
                  onEnterActionFunc={() => modalAction()}
                  onInput={(e) => setEmp({ ...emp, address: e })}
                  isReq={true}
                />
                <AppSelect
                  className="modal-input"
                  label="Сотрудник принадлежит следующей организации"
                  onInput={(e) => setEmp({ ...emp, organization: e })}
                  selected={searchOrg(emp?.organization)}
                  optionsList={organizationsData}
                  mode="white"
                  isReq={true}
                />
                <AppSelect
                  label="Район работы"
                  optionsList={districtListData}
                  validations={{ isEmpty: true }}
                  selected={searchDis(emp?.district)}
                  onInput={(e) => {
                    setEmp({ ...emp, district: e });
                  }}
                  isDisabled={isAdmin === 'false'}
                  mode="white"
                  isReq={true}
                />
                {isAdmin === 'true' && (
                    <AppCheckbox label="Активный?" value={emp?.active} checked={emp?.active} onChange={() => setEmp({ ...emp, active: !emp?.active }) } />
                )}
                <div className="spec-wrapper">
                    <div className="spec-variants">
                      <h4 className="area-name">Услуги исполнителя<div className="req">*</div></h4>
                      {!!servicesListData?.length &&
                        servicesListData.map((option, idx) => {
                          return (
                            <div className="spec-area" key={idx}>
                              <div className="spec-item">
                                <AppCheckbox
                                  mode="blue"
                                  checked={option?.selected}
                                  onChange={() => setSelectedSpec(idx)}
                                />
                                <h3 className="serv-item-name">
                                  {option?.name}
                                </h3>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                </div>
              </div>
              <AppButton
                isDisabled={isDisabledActionButton()}
                onClick={modalAction}
              >
                Сохранить изменения
              </AppButton>
            </div>
          </div>
        </div>
        <div className="person-right-side">
          <div className="person-content">
            <h1 className="stats-modal-name">История закрытых заявок</h1>
            <div className="search-wrapper">
              <AppSelect
                optionsList={status}
                defaultText="Выбрать статус"
                label="Статус работы"
                mode="grey"
                onInput={setStatusDataId}
              />
              <AppSelect
                optionsList={ordering}
                label="Сортировать по:"
                defaultText="Выбрать сортировку"
                onInput={setOrderingId}
              />
            </div>
            <div className="stats-area" ref={statsAreaTop}>
              <div className="discount-variants">
                {!!dispatcherOrderListData?.length &&
                  dispatcherOrderListData.map((option, idx) => {
                    return (
                      <div className="work-stat" key={idx}>
                        <div className="stat-line">
                          <AppInput
                            label="№ Заявки"
                            value={option?.id}
                            isDisabled={true}
                          />
                          <AppInput
                            label="Дата и время заказа"
                            value={`${option.date}, ${timesName(option?.time)}`}
                            isDisabled={true}
                          />
                        </div>
                        <div className="stat-second-line">
                          <AppInput
                            className="stat-input"
                            label="Заказчик"
                            value={option?.customer_name}
                            isDisabled={true}
                          />
                          <AppInput
                            label="Тип услуги"
                            value={option?.service.name}
                            isDisabled={true}
                          />
                        </div>
                        {!!option?.order_addresses?.length &&
                          option?.order_addresses.map((el) => {
                            return (
                              <AppInput
                                className="stat-input"
                                label="Адрес доставки"
                                value={el.address?.address}
                                isDisabled={true}
                              />
                            );
                          })}
                        <AppInput
                          className="stat-input"
                          label="Статус"
                          value={statusFormat(option.status)}
                          isDisabled={true}
                        />
                      </div>
                    );
                  })}
                {!dispatcherOrderListData?.length && (
                  <AppEmpty desc="Нет заявок" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ExecutorAccModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

ExecutorAccModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ExecutorAccModal;
