import {refreshTokensAsync} from "../store/reducers/auth";
import {getDispatcherProfileAsync} from "../store/reducers/dispatcher/profile";
import {addError} from "../store/reducers/alerts";
import {RouterPath} from "../utils/utils";

export const timesName = (time) => {
  const times = {
    "10-13": "с 10:00 до 13:00",
    "14-18": "c 14:00 до 18:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
  };
  return times[time];
};

export const convertTimes = (timesArr) => {
  const timesArrObj = [];
  if (timesArr) {
    for (const time of timesArr) {
      if (time === "10") timesArrObj.push({ id: "10", name: "10:00" });
      if (time === "11") timesArrObj.push({ id: "11", name: "11:00" });
      if (time === "12") timesArrObj.push({ id: "12", name: "12:00" });
      if (time === "13") timesArrObj.push({ id: "13", name: "13:00" });
      if (time === "14") timesArrObj.push({ id: "14", name: "14:00" });
      if (time === "15") timesArrObj.push({ id: "15", name: "15:00" });
      if (time === "16") timesArrObj.push({ id: "16", name: "16:00" });
      if (time === "17") timesArrObj.push({ id: "17", name: "17:00" });
      if (time === "10-13")
        timesArrObj.push({ id: "10-13", name: "c 10:00 до 13:00" });
      if (time === "14-18")
        timesArrObj.push({ id: "14-18", name: "c 14:00 до 18:00" });
    }
  }
  return timesArrObj;
};

export const convertQuantities = (quantities, measure) => {
  if (!!quantities?.length && measure) {
    const arr = [];
    const volumes = {
      volume: "m3",
      time: "ч",
      weight: "тонн",
      barrel: "боч.",
    };
    for (const item of quantities) {
      arr.push({ id: item, name: `${item} ${volumes[measure]}` });
    }
    return arr;
  } else return [];
};

export const convertPriceType = (price_type) => {
  const types = {
    volume: "Объем (1 м3)",
    time: "Время (1 час)",
    weight_dist: "Вес/расстояние (1 тн./км)",
    passage: "Рейс",
    barrel: "Бочка (200 л.)",
  };
  return types[price_type];
};

export const convertDate = (date, mode = "dot") => {
  if (date) {
    if (mode === "dot" && date.search(".") === 0 && date.search("-") !== 4) {
      const arrDate = date?.split(".");
      return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    }
    if (mode === "dot" && date.search("-") === 4) {
      return date;
    }
  }
};

export function convertDateToDot(date) {
  if (date) {
    if (!date.search(".")) {
      const arrDate = date?.split("-");
      return `${arrDate[2]}.${arrDate[1]}.${arrDate[0]}`;
    } else return date;
  }
}

// export const customerTypeOdj = (customer_type) => {
//   const customerTypes = {
//     [localStorage.getItem("legal-id")]: {
//       id: localStorage.getItem("legal-id"),
//       name: "Юр.лицо",
//     },
//     [localStorage.getItem("ip-id")]: {
//       id: localStorage.getItem("ip-id"),
//       name: "ИП",
//     },
//   };
//   return customerTypes[customer_type];
// };

export const customerTypeOdjApp = (customer_type) => {
  const customerTypes = {
    [localStorage.getItem("legal-id")]: {
      id: localStorage.getItem("legal-id"),
      name: "Юр.лицо",
    },
    [localStorage.getItem("ip-id")]: {
      id: localStorage.getItem("ip-id"),
      name: "ИП",
    },
  };
  return customerTypes[customer_type];
};

export const customerTypeId = (customer_type) => {
  /*const customerTypes = {
    [localStorage.getItem("legal-id")]: "legal",
    [localStorage.getItem("ip-id")]: "ip",
  };*/
  if(customer_type === "Юр.лицо")
    return "legal"
  if(customer_type === "ИП")
    return "ip"

  return null
  /*return customerTypes[customer_type];*/
};

export const statusFormat = (status) => {
  const statuses = {
    wait_approve: "Ожидает подтверждения",
    created: "Создано",
    canceled: "Отклонено",
    approved: "Подтверждено диспетчером",
    in_progress: "В работе",
    work_is_done: "Работа выполнена",
    wait_payment: "Ожидает оплаты",
    finished: "Завершено",
  };
  return statuses[status];
};

export const orderHistoryActionFormat = (action) => {
  const actions = {
    status: "Изменение статуса заявки",
    changes: "Изменения полей заявки",
    payment: "Совершение оплаты",
  };
  return actions[action];
};
export const EmpTypeConverter = (type) => {
  const types = {
    water: "Водовоз",
    waste: "Ассенизатор",
    cran: "Кран",
    excavator: "Экскаватор",
    boer: "Буровая",
    bulldozer: "Бульдозер",
    loader: "Погрузчик",
    tipper: "Самосвал",
    tractor: "Тягач",
    oil: "Нефтевоз",
  };
  return types[type];
};

export const timeFormat = (time) => {
  let timeParts = [];
  if (time) {
    timeParts = time.split("T");
    return `${timeParts[0]}, ${timeParts[1]}`;
  }
};

export async function updateTokens(err, dispatch, logErrTitle) {
  if (err?.response?.status === 401) {
    if (!!localStorage.getItem("refresh")) {
      dispatch(
        refreshTokensAsync({ refresh: localStorage.getItem("refresh") })
      ).then(() => {
        dispatch(getDispatcherProfileAsync());
        return "ok";
      });
    } else if (!localStorage.getItem("refresh")) {
      localStorage.removeItem("access");
      return "fail";
    }
  } else {
    return "fail";
  }
}

export const findInServicesList = (option, servicesList) => {
  return servicesList.filter((el) => el.id === option.id || option)[0];
};

export const convertPrice = (price, mode) => {
  if (!!price) {
    const priceStr = price.toString()
    if (mode === "back") {
      if (priceStr.search(/[\s.,%]/g) !== -1) {
        const two = priceStr?.length - priceStr.search(/[\s.,%]/g) - 1
        if (two === 2) {
          return priceStr.replace(/[\s.,%]/g, "")
        } else {
          return priceStr.replace(/[\s.,%]/g, "") * 10
        }
      }
      if (priceStr.search(/[\s.,%]/g) === -1) {
        return price * 100;
      }
    }
    if (mode === "front") {
      return price / 100;
    }
  }
  if (!mode) console.error("Укажите mode для convertPrice");
  if (!price) return price;
};

export const errorHandler = ({ error, text, requestName, dispatch }) => {
  console.log(error, text, requestName);
  const resData = error?.response?.data;
  const resStatus = error?.response?.status;
  const errorArr = [];

  if (resStatus === 500) {
    errorArr.push("Ошибка сервера")
  }

  if (resStatus === 403) {
    errorArr.push("Недостаточно прав для просмотра")
  }

  if (resStatus === 401) {
    const refreshToken = localStorage.getItem("refresh");
    dispatch(refreshTokensAsync({ refresh: refreshToken })).then((r) => {
      if ([201, 200].includes(r.status)) {
        localStorage.setItem("access", r?.data?.access);
        localStorage.setItem("refresh", r?.data?.refresh);
      }
    }).catch((e) => {
      if (e?.response?.status === 401) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        window.location.href = RouterPath.auth
      }
    })
  }

  if (resStatus === 400) {
    if (!!resData?.username?.length) {
      for (const e of resData?.username) {
        errorArr.push(e)
      }
    }
    if (!!resData?.type?.length) {
      for (const e of resData?.type) {
        errorArr.push(e)
      }
    }
    if (!!resData?.fio?.length) {
      for (const e of resData?.fio) {
        errorArr.push(e)
      }
    }
    if (!!resData?.phone?.length) {
      for (const e of resData?.phone) {
        errorArr.push(e)
      }
    }
    if (!!resData?.email?.length) {
      for (const e of resData?.email) {
        errorArr.push(e)
      }
    }
    if (!!resData?.organization?.length) {
      for (const e of resData?.organization) {
        errorArr.push(e)
      }
    }
    if (!!resData?.photo?.length) {
      for (const e of resData?.photo) {
        errorArr.push(e)
      }
    }
    if (!!resData?.district?.length) {
      for (const e of resData?.district) {
        errorArr.push(e)
      }
    }
    if (!!resData?.address?.length) {
      for (const e of resData?.address) {
        errorArr.push(e)
      }
    }
    if (!!resData?.services?.length) {
      for (const e of resData?.services) {
        errorArr.push(e)
      }
    }
    if (!!resData?.password?.length) {
      for (const e of resData?.password) {
        errorArr.push(e)
      }
    }
    if (!!resData?.comment?.length) {
      for (const e of resData?.comment) {
        errorArr.push(e)
      }
    }
    if (!!resData?.current_district?.length) {
      for (const e of resData?.common_district) {
        errorArr.push(e)
      }
    }
    if (!!resData?.service?.length) {
      for (const e of resData?.service) {
        errorArr.push(e)
      }
    }
    if (!!resData?.quantity?.length) {
      for (const e of resData?.quantity) {
        errorArr.push(e)
      }
    }
    if (!!resData?.date?.length) {
      for (const e of resData?.date) {
        errorArr.push(e)
      }
    }
    if (!!resData?.customer_comment?.length) {
      for (const e of resData?.customer_comment) {
        errorArr.push(e)
      }
    }
    if (!!resData?.order_addresses?.length) {
      for (const e of resData?.order_addresses) {
        errorArr.push(e)
      }
    }
    if (!!resData?.id?.length) {
      for (const e of resData?.id) {
        errorArr.push(e)
      }
    }
    if (!!resData?.bill?.length) {
      for (const e of resData?.bill) {
        errorArr.push(e)
      }
    }
    if (!!resData?.file?.length) {
      for (const e of resData?.file) {
        errorArr.push(e)
      }
    }
    if (!!resData?.content_type?.length) {
      for (const e of resData?.content_type) {
        errorArr.push(e)
      }
    }
    if (!!resData?.object_id?.length) {
      for (const e of resData?.object_id) {
        errorArr.push(e)
      }
    }
    if (!!resData?.ceo_fio?.length) {
      for (const e of resData?.ceo_fio) {
        errorArr.push(e)
      }
    }
    if (!!resData?.inn?.length) {
      for (const e of resData?.inn) {
        errorArr.push(e)
      }
    }
    if (!!resData?.bank_name?.length) {
      for (const e of resData?.bank_name) {
        errorArr.push(e)
      }
    }
    if (!!resData?.bank_bik?.length) {
      for (const e of resData?.bank_bik) {
        errorArr.push(e)
      }
    }
    if (!!resData?.bank_account?.length) {
      for (const e of resData?.bank_account) {
        errorArr.push(e)
      }
    }
    if (!!resData?.bank_kor_account?.length) {
      for (const e of resData?.bank_kor_account) {
        errorArr.push(e)
      }
    }
    if (!!resData?.full_name?.length) {
      for (const e of resData?.full_name) {
        errorArr.push(e)
      }
    }
    if (!!resData?.short_name?.length) {
      for (const e of resData?.short_name) {
        errorArr.push(e)
      }
    }
    if (!!resData?.legal_address?.length) {
      for (const e of resData?.legal_address) {
        errorArr.push(e)
      }
    }
    if (!!resData?.actual_address?.length) {
      for (const e of resData?.actual_address) {
        errorArr.push(e)
      }
    }
    if (!!resData?.post_address?.length) {
      for (const e of resData?.post_address) {
        errorArr.push(e)
      }
    }
    if (!!resData?.ogrn?.length) {
      for (const e of resData?.ogrn) {
        errorArr.push(e)
      }
    }
    if (!!resData?.kpp?.length) {
      for (const e of resData?.kpp) {
        errorArr.push(e)
      }
    }
    if (!!resData?.executive_agency?.length) {
      for (const e of resData?.executive_agency) {
        errorArr.push(e)
      }
    }
    if (!!resData?.acts_on_basis?.length) {
      for (const e of resData?.acts_on_basis) {
        errorArr.push(e)
      }
    }
    if (!!resData?.passport?.length) {
      for (const e of resData?.passport) {
        errorArr.push(e)
      }
    }
    if (!!resData?.birth_date?.length) {
      for (const e of resData?.birth_date) {
        errorArr.push(e)
      }
    }
    if (!!resData?.ogrnip?.length) {
      for (const e of resData?.ogrnip) {
        errorArr.push(e)
      }
    }
    if (!!resData?.active?.length) {
      for (const e of resData?.active) {
        errorArr.push(e)
      }
    }
    if (!!resData?.name?.length) {
      for (const e of resData?.name) {
        errorArr.push(e)
      }
    }
    if (!!resData?.customer_types?.length) {
      for (const e of resData?.customer_types) {
        errorArr.push(e)
      }
    }
    if (!!resData?.measure?.length) {
      for (const e of resData?.measure) {
        errorArr.push(e)
      }
    }
    if (!!resData?.quantities?.length) {
      for (const e of resData?.quantities) {
        errorArr.push(e)
      }
    }
    if (!!resData?.times?.length) {
      for (const e of resData?.times) {
        errorArr.push(e)
      }
    }
    if (!!resData?.price_type?.length) {
      for (const e of resData?.price_type) {
        errorArr.push(e)
      }
    }
    if (!!resData?.destination?.length) {
      for (const e of resData?.destination) {
        errorArr.push(e)
      }
    }
    if (!!resData?.price?.length) {
      for (const e of resData?.price) {
        errorArr.push(e)
      }
    }
    if (!!resData?.customer_type?.length) {
      for (const e of resData?.customer_type) {
        errorArr.push(e)
      }
    }
    if (!!resData?.customer_id?.length) {
      for (const e of resData?.customer_id) {
        errorArr.push(e)
      }
    }
    if (!!resData?.executor?.length) {
      for (const e of resData?.executor) {
        errorArr.push(e)
      }
    }
    if (!!resData?.status?.length) {
      if(Array.isArray(resData.status))
        for (const e of resData?.status) {
          errorArr.push(e)
        }
      else
        errorArr.push(resData?.status)
    }
    if (!!resData?.time?.length) {
      for (const e of resData?.time) {
        errorArr.push(e)
      }
    }
    if (!!resData?.dispatcher_comment?.length) {
      for (const e of resData?.dispatcher_comment) {
        errorArr.push(e)
      }
    }
    if (!!resData?.discounts?.length) {
      for (const e of resData?.discounts) {
        errorArr.push(e)
      }
    }
    if (!!resData?.order?.length) {
      for (const e of resData?.order) {
        errorArr.push(e)
      }
    }
    if (!!resData?.amount?.length) {
      for (const e of resData?.amount) {
        errorArr.push(e)
      }
    }
    if (!!resData.non_field_errors?.length) {
      for (const e of resData?.non_field_errors) {
        errorArr.push(e)
      }
    }
  } else {
    if (text?.length) {
      dispatch(addError(text));
    }
  }
  if (!!errorArr?.length) {
    const uniqErrorArr = errorArr.reduce((acc, item) => {
      if (acc.includes(item)) {
        return acc;
      }
      return [...acc, item];
    }, []);
    if (!!uniqErrorArr?.length) {
      for (const error of uniqErrorArr) {
        dispatch(addError(error));
      }
    }
  }
};
