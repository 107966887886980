import React from "react";
import "./styles/LogoutModal.scss";
import AppButton from "../../../AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addSuccess } from "../../../../../store/reducers/alerts";
import { setDispatcherProfile } from "../../../../../store/reducers/dispatcher/profile";
import { RouterPath } from "../../../../../utils/utils";
import PropTypes from "prop-types";

const LogoutModal = ({ closeModal, options }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function logout() {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem('isAdmin');
    localStorage.removeItem("lifetime_access_min");
    localStorage.removeItem("lifetime_refresh_min");
    dispatch(addSuccess("Выход успешно выполнен!"));
    dispatch(setDispatcherProfile({}));
    closeModal();
    navigate(RouterPath.auth);
  }

  return (
    <>
      <div className="logout-modal__container">
        <h2 className="logout-modal__title">Вы действительно хотите выйти?</h2>
        <div className="logout-modal__buttons">
          <AppButton onClick={() => logout()}>Да, я уверен</AppButton>
          <AppButton onClick={() => closeModal()}>Нет, я передумал</AppButton>
        </div>
      </div>
    </>
  );
};

LogoutModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

LogoutModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default LogoutModal;
