import AppCheckbox from "../../../../../layout/AppCheckbox/AppCheckbox";
import AppInput from "../../../../../layout/AppInput/AppInput";
import { convertPriceType } from "../../../../../../scripts/commonScripts";
import React, { useContext } from "react";
import { RatesModalContext } from "../RatesModal";

const RatesItem = ({ el, index }) => {
  const { setFilteredTariffsCheck, isAdmin, setTariffsData } =
    useContext(RatesModalContext);
  return (
    <div className="district-item" key={index}>
      <AppCheckbox
        checked={el?.active}
        isDisabled={!isAdmin}
        onChange={(isChecked) => setFilteredTariffsCheck(isChecked, index)}
      />
      <AppInput
        placeholder="Название услуги"
        value={el?.service.name}
        isDisabled={true}
      />
      <AppInput
        placeholder="Тип цены"
        value={convertPriceType(el?.service.price_type)}
        isDisabled={true}
      />
      <AppInput
        placeholder="Не указана"
        type="number"
        value={el?.price?.toString().length === 1 ? null : el?.price}
        validations={{ isNumbersWithDot: true }}
        isDisabled={!isAdmin}
        onInput={(price) => {
          setTariffsData(index, price);
        }}
      />
    </div>
  );
};

export default RatesItem;
