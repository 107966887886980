import React, { useEffect, useState } from "react";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiscountListAsync,
  getOrganizationListAsync,
} from "../../../store/reducers/dispatcher/catalog";
import {
  getDispatcherOrderCountAsync,
  getDispatcherOrderListAsync,
} from "../../../store/reducers/dispatcher/order";
import EventEmitter from "../../../utils/EventEmitter";
import { appListPerPage, updatePagesPeriod } from "../../../utils/constants";
import { getServicesListAsync } from "../../../store/reducers/common/catalog";
import { RouterPath } from "../../../utils/utils";
const Applications = () => {
  const currLocation = window.location.pathname;
  const dispatch = useDispatch();
  const headerList = useSelector(
    (state) => state.tables.applicationListHeaders
  );
  const { dispatcherOrderListCount, dispatcherOrderList } = useSelector(
    (state) => state.dispatcherOrder
  );
  const [AppProcessActive, setAppProcessActive] = useState(false);
  const [applicationListData, setApplicationListData] =
    useState(dispatcherOrderList);
  const [status, setStatus] = useState("");
  const [searchData, setSearchData] = useState("");
  const [pageData, setPageData] = useState(1);

  useEffect(() => {
    dispatch(getDiscountListAsync());
    dispatch(
      getDispatcherOrderListAsync({
        page: pageData,
        page_size: appListPerPage,
        status: status,
        q: searchData,
      })
    );
    dispatch(getDispatcherOrderCountAsync());
    dispatch(getServicesListAsync({ page: 1, page_size: 150000 }));
    dispatch(getOrganizationListAsync());
  }, []);

  useEffect(() => {
    const updatePage = setInterval(() => {
      if (currLocation === RouterPath.apps) {
        dispatch(getDiscountListAsync());
        if (!sessionStorage.getItem("isOpenedCreateServiceModal")) {
          dispatch(getServicesListAsync({ page: 1, page_size: 150000 }));
          dispatch(
            getDispatcherOrderListAsync({
              page: pageData,
              page_size: appListPerPage,
              status: status,
              q: searchData,
            })
          );
        }

        dispatch(getDispatcherOrderCountAsync());
        dispatch(getOrganizationListAsync());
      }
    }, updatePagesPeriod);
    return () => clearInterval(updatePage);
  }, [dispatch, pageData, searchData, status]);

  useEffect(() => {
    setTimeout(() => {
      setApplicationListData(dispatcherOrderList);
    }, 0);
  }, [dispatcherOrderList]);

  useEffect(() => {
    const filterStatusListener = EventEmitter.addListener(
      "AppsFiltersStatus",
      (e) => {
        function checkStatus(status) {
          if (typeof status === "string") return status;
          else return status?.join(",");
        }
        setStatus(e?.status ? checkStatus(e?.status) : "");
        dispatch(
          getDispatcherOrderListAsync({
            page: pageData,
            page_size: appListPerPage,
            status: checkStatus(e?.status),
          })
        );
      }
    );
    const pageListener = EventEmitter.addListener("page", (page) => {
      setPageData(page);
      dispatch(
        getDispatcherOrderListAsync({
          page: page,
          page_size: appListPerPage,
          status: status,
          q: searchData,
        })
      );
    });
    const searchListener = EventEmitter.addListener("search", (search) => {
      setSearchData(search);
      dispatch(
        getDispatcherOrderListAsync({
          page: 1,
          page_size: appListPerPage,
          q: search,
        })
      );
    });
    return () => {
      filterStatusListener.remove();
      pageListener.remove();
      searchListener.remove();
    };
  });

  return (
    <AppMainTable
      title="Заявки"
      search="Поиск заявки"
      headerList={headerList}
      itemList={applicationListData}
      itemListTotalCount={dispatcherOrderListCount}
      AppProcessActive={AppProcessActive}
      SetAppProcessActive={setAppProcessActive}
      mode="appTable"
      perPage={appListPerPage}
    />
  );
};

export default Applications;
