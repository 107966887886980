import React, { useEffect } from "react";
import "./styles/AppAlert.scoped.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteError,
  deleteInfo,
  deleteSuccess,
} from "../../../store/reducers/alerts";
import { AppAlertsTimeouts } from "../../../utils/constants";

const AppAlert = () => {
  const dispatch = useDispatch();

  const { errors, successes, infos } = useSelector((store) => store.alerts);

  function closeError(error) {
    dispatch(deleteError(error));
  }

  function closeSuccess(success) {
    dispatch(deleteSuccess(success));
  }

  function closeInfo(info) {
    dispatch(deleteInfo(info));
  }

  useEffect(() => {
    if (!!successes.length) {
      for (const success of successes) {
        setTimeout(() => {
          dispatch(deleteSuccess(success));
        }, AppAlertsTimeouts.success);
      }
    }
    if (!!errors.length) {
      for (const error of errors) {
        setTimeout(() => {
          dispatch(deleteError(error));
        }, AppAlertsTimeouts.error);
      }
    }
    if (!!infos.length) {
      for (const info of infos) {
        setTimeout(() => {
          dispatch(deleteInfo(info));
        }, AppAlertsTimeouts.info);
      }
    }
  }, [dispatch, errors, infos, successes]);

  return (
    <div className="alert">
      {!!errors.length &&
        errors.map((error, idx) => {
          return (
            <div key={idx} className="alert__item error">
              <button
                className="alert__close"
                type="button"
                onClick={() => closeError(error)}
              >
                X
              </button>
              <div className="alert__text">{error}</div>
            </div>
          );
        })}
      {!!successes.length &&
        successes.map((success, idx) => {
          return (
            <div key={idx} className="alert__item success">
              <button
                className="alert__close"
                type="button"
                onClick={() => closeSuccess(success)}
              >
                X
              </button>
              <div className="alert__text">{success}</div>
            </div>
          );
        })}
      {!!infos.length &&
        infos.map((info, idx) => {
          return (
            <div key={idx} className="alert__item info">
              <button
                className="alert__close"
                type="button"
                onClick={() => closeInfo(info)}
              >
                X
              </button>
              <div className="alert__text">{info}</div>
            </div>
          );
        })}
    </div>
  );
};

export default AppAlert;
