import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { APIUrlPrefix } from "../../../utils/constants";
import { addSuccess } from "../alerts";
import { errorHandler } from "../../../scripts/commonScripts";

const dispatcherOrderSlice = createSlice({
  name: "dispatcherOrder",
  initialState: {
    dispatcherOrderListForTaskList: [],
    dispatcherOrderListCountForTaskList: 0,
    dispatcherOrderList: [],
    setDispatcherOrderListCount: 0,
    dispatcherOrder: {},
    dispatcherOrderCount: {},
    dispatcherOrderHistory: [],
  },
  reducers: {
    setDispatcherOrderListForTaskList: (state, action) => {
      state.dispatcherOrderListForTaskList = [...action.payload];
    },
    setDispatcherOrderListCountForTaskList: (state, action) => {
      state.dispatcherOrderListCountForTaskList = action.payload;
    },
    setDispatcherOrderList: (state, action) => {
      state.dispatcherOrderList = [...action.payload];
    },
    setDispatcherOrderListCount: (state, action) => {
      state.dispatcherOrderListCount = action.payload;
    },
    setDispatcherOrder: (state, action) => {
      state.dispatcherOrder = action.payload;
    },
    setDispatcherOrderHistory: (state, action) => {
      state.dispatcherOrderHistory = action.payload;
    },
    setDispatcherOrderCount: (state, action) => {
      state.dispatcherOrderCount = action.payload;
    },
  },
});

export const getDispatcherOrderListAsync =
  (params, mode = "default") =>
  async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/dispatcher/order/order/`, { params: params })
      .then((r) => {
        if (mode === "default") {
          dispatch(setDispatcherOrderList(r?.data?.results));
          dispatch(setDispatcherOrderListCount(r?.data?.count));
        } else {
          dispatch(setDispatcherOrderListForTaskList(r?.data?.results));
          dispatch(setDispatcherOrderListCountForTaskList(r?.data?.count));
        }
      })
        .catch((e) => {
          errorHandler({
            error: e,
            dispatch,
            text: "",
            requestName: "getDispatcherOrderListAsync",
          });
        });
  };

export const getDispatcherOrderByIdAsync = (id) => async (dispatch) => {
  await api
      .get(`${APIUrlPrefix}/dispatcher/order/order/${id}/`)
      .then((r) => {
    dispatch(setDispatcherOrder(r?.data));
  })
      .catch((e) => {
    errorHandler({
      error: e,
      dispatch,
      text: "",
      requestName: "getDispatcherOrderByIdAsync",
    });
  });
};

export const editDispatcherOrderByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/order/order/${id}/`, data)
    .then(() => {
      dispatch(addSuccess("Заказ успешно изменен!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка редактирования заказа!",
        requestName: "editDispatcherOrderByIdAsync",
      });
    });
};

export const getDispatcherOrderHistoryByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/order/order_history/${id}/`)
    .then((r) => {
      dispatch(setDispatcherOrderHistory(r?.data));
    })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDispatcherOrderHistoryByIdAsync",
        });
      });
};

export const getDispatcherOrderCountAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/order/count/`)
    .then((r) => {
      dispatch(setDispatcherOrderCount(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getDispatcherOrderCountAsync",
      });
    });
};

export const createDispatcherOrderAsync = (data, returnResponse) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/order/order/`, data)
    .then((r) => {
        dispatch(addSuccess("Заказ успешно создан!"));
        dispatch(setDispatcherOrder(r?.data));
        return returnResponse(r?.data)
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка создания заказа!",
        requestName: "createDispatcherOrderAsync",
      });
    });
};

export const createDispatcherOrderAddressAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/order/order_address/`, data)
    .then(() => {
      dispatch(addSuccess("Адрес успешно создан!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка создания адреса!",
        requestName: "createDispatcherOrderAddressAsync",
      });
    });
};

export const editDispatcherOrderAddressAsync =
  (id, data) => async (dispatch) => {
    await api
      .patch(`${APIUrlPrefix}/dispatcher/order/order_address/${id}/`, data)
      .then(() => {
        dispatch(addSuccess("Адрес успешно отредактирован!"));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "Ошибка редактирования адреса!",
          requestName: "editDispatcherOrderAddressAsync",
        });
      });
  };

export const {
  setDispatcherOrderListForTaskList,
  setDispatcherOrderListCountForTaskList,
  setDispatcherOrderList,
  setDispatcherOrderListCount,
  setDispatcherOrder,
  setDispatcherOrderCount,
  setDispatcherOrderHistory,
} = dispatcherOrderSlice.actions;
export default dispatcherOrderSlice.reducer;
