import React, { useEffect, useState } from "react";
import "../styles/ApplilcationProcessModalCardMain.scoped.scss";
import ApplicationProcessModalCardEmp from "./ApplicationProcessModalCardEmp";
import ApplicationProcessModalCardTask from "./ApplicationProcessModalCardTask";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getDispatcherOrderListAsync } from "../../../../../../store/reducers/dispatcher/order";
import AppEmpty from "../../../../../layout/AppEmpty/AppEmpty";
import "../../../styles/ApplicationModal.scoped.scss";

const ApplicationProcessModalCardMain = ({
  executor,
  isOpened,
  isShowStatus,
  selectExecutor,
  isDisableCloseTasks,
  setModalView,
  isShowSelect,
  isShowDDIcon,
  filteredExecutors0,
  setFilteredExecutors0,
  filteredExecutors1,
  setFilteredExecutors1,
}) => {
  const dispatch = useDispatch();
  const { dispatcherOrderListForTaskList } = useSelector(
    (state) => state.dispatcherOrder
  );
  const [isOpenTasks, setOpenTasks] = useState(isOpened);
  const cardEmpMainClasses = classNames(
    "card-emp-main",
    "mode-executor__flex-mode-item",
    cardEmpMainClassModes()
  );

  function cardEmpMainClassModes() {
    if (!isDisableCloseTasks) return "card-emp-main-with-hover";
    else return "";
  }

  useEffect(() => {
    dispatch(
      getDispatcherOrderListAsync(
        {
          page: 1,
          page_size: 150000,
          executor: executor.id,
          status: "approved,in_progress",
          ordering: "-status,date",
        },
        "list"
      )
    );
  }, [executor.id]);

  useEffect(() => {
      console.log('isOpened', isOpened)
      console.log('dispatcherOrderListForTaskList', dispatcherOrderListForTaskList)
      console.log('isOpenTasks', isOpenTasks)
  }, [isOpened, isOpenTasks])

  return (
    <div
      className={cardEmpMainClasses}
      onClick={
        isDisableCloseTasks
          ? () => {}
          : (e) => {
              e.stopPropagation();
              setOpenTasks((prevValue) => !prevValue);
              dispatch(
                getDispatcherOrderListAsync(
                  {
                    page: 1,
                    page_size: 150000,
                    executor: executor.id,
                    status: "approved,in_progress",
                    ordering: "-status,date",
                  },
                  "list"
                )
              );
            }
      }
    >
      <ApplicationProcessModalCardEmp
        filteredExecutors0={filteredExecutors0}
        filteredExecutors1={filteredExecutors1}
        setFilteredExecutors0={setFilteredExecutors0}
        setFilteredExecutors1={setFilteredExecutors1}
        setModalView={setModalView}
        executor={executor}
        arrNumber={executor.arr}
        isOpened={executor?.selected}
        isShowStatus={isShowStatus}
        isShowDDIcon={isShowDDIcon}
        status={executor?.status}
        isShowSelect={isShowSelect}
        selectExecutor={selectExecutor}
        mode={executor?.selected ? "with-border" : ""}
      />
      {(isOpened || executor?.selected) && (
        <>
          <div className="card-emp-main__delimiter" />
          <div className="card-emp-main__title">Список задач</div>
          <div className="card-emp-main__tasks">
            {!dispatcherOrderListForTaskList?.length && (
              <AppEmpty desc="Нет задач" />
            )}
            {!!dispatcherOrderListForTaskList?.length &&
              dispatcherOrderListForTaskList?.map((task, idx) => {
                return (
                  <ApplicationProcessModalCardTask task={task} key={idx} />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

ApplicationProcessModalCardMain.defaultProps = {
  executor: {
    name: "Не указано",
    email: "Не указано",
    phone: "Не указано",
  },
  isOpened: false,
  isShowStatus: false,
  isDisableCloseTasks: false,
  isShowDDIcon: false,
};
ApplicationProcessModalCardMain.propTypes = {
  executor: PropTypes.object,
  isOpened: PropTypes.bool,
  isShowStatus: PropTypes.bool,
  isDisableCloseTasks: PropTypes.bool,
  isShowDDIcon: PropTypes.bool,
};

export default ApplicationProcessModalCardMain;
