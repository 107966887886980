import React, { useEffect } from "react";
import AppSelect from "../../../layout/AppSelect/AppSelect";
import AppButton from "../../../layout/AppButton/AppButton";
import "../styles/ApplicationModal.scoped.scss";
import { setIsClosable } from "../../../../store/reducers/modals";
import { useDispatch } from "react-redux";
import { EmpTypeConverter } from "../../../../scripts/commonScripts";

const ApplicationSelectExecutor = ({
  executor,
  executorId,
  executorFullData,
  organizationsData,
  setOrganizationId,
  setModalView,
  organizationId,
}) => {
  const dispatch = useDispatch();

  function findOrganizationsDataInArr(id) {
    return organizationsData.filter((el) => el.id === id)[0];
  }

  useEffect(() => {
    dispatch(setIsClosable(true));
  }, []);
  return (
    <>
      <div className="executors">
        {(executor?.id || executorId?.id) && (
          <>
            <div className="executors__container">
              <img
                className="executors__photo"
                src={
                  executorFullData?.photo ||
                  executor?.photo ||
                  require("../../../images/logo512.png")
                }
                alt=""
              />
              <div className="executors__container-name">
                <div className="executors__container-name-fio">
                  {executorFullData?.fio || executor?.fio}
                </div>
                <div className="executors__container-name-fio">
                  {EmpTypeConverter(executorFullData?.type || executor?.type)}
                </div>
                <div>
                  {executorFullData?.organization?.name ||
                    executor?.organization?.name}
                </div>
              </div>
            </div>
            <div className="area-label">
              Желаете выбрать другого исполнителя?
            </div>
            <AppSelect
              label="Обслуживающая организация"
              optionsList={organizationsData}
              onInput={setOrganizationId}
              defaultText="Укажите организацию"
              selected={findOrganizationsDataInArr(organizationId)}
              mode="white"
            />
            <AppButton
              onClick={() => setModalView("executor")}
              isDisabled={!organizationId}
            >
              Выбрать исполнителя
            </AppButton>
          </>
        )}
        {!(executor?.id || executorId?.id) && (
          <>
            <AppSelect
              label="Обслуживающая организация"
              optionsList={organizationsData}
              onInput={setOrganizationId}
              defaultText="Укажите организацию"
              selected={findOrganizationsDataInArr(organizationId)}
              mode="grey"
              isReq={true}
            />
            <AppButton
              onClick={() => setModalView("executor")}
              isDisabled={!organizationId}
            >
              Выбрать исполнителя
            </AppButton>
          </>
        )}
      </div>
    </>
  );
};

export default ApplicationSelectExecutor;
