import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { APIUrlPrefix } from "../../../utils/constants";
import {errorHandler} from "../../../scripts/commonScripts";

const customerAccountSlice = createSlice({
  name: "customerAccount",
  initialState: {
    customerAddressList: [],
    customerAddress: {},
    companyProfile: {},
    physicalPersonProfile: {},
  },
  reducers: {
    setCustomerAddressList: (state, action) => {
      state.customerAddressList = [...action.payload];
    },
    setCustomerAddress: (state, action) => {
      state.customerAddress = action.payload;
    },
    setCompanyProfile: (state, action) => {
      state.companyProfile = action.payload;
    },
    setPhysicalPersonProfile: (state, action) => {
      state.physicalPersonProfile = action.payload;
    },
  },
});

export const getCustomerAddressListAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/customer/account/address/`)
    .then((r) => {
      dispatch(setCustomerAddressList(r?.data?.result));
    }).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getCustomerAddressListAsync",
        });
      });
};

export const createCustomerAddressAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/customer/account/address/`, data).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "createCustomerAddressAsync",
        });
      });
};

export const getCustomerAddressByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/customer/account/address/${id}`)
    .then((r) => {
      dispatch(setCustomerAddress(r?.data));
    }).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getCustomerAddressByIdAsync",
        });
      });
};

export const editCustomerAddressByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/customer/account/address/${id}`, data)
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "editCustomerAddressByIdAsync",
        });
      });
};

export const getCompanyProfileAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/customer/account/company/`)
    .then((r) => {
      dispatch(setCompanyProfile(r?.data));
    })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getCompanyProfileAsync",
        });
      });
};

export const getPhysicalPersonProfileAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/customer/account/individual/`)
    .then((r) => {
      dispatch(setPhysicalPersonProfile(r?.data));
    })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getPhysicalPersonProfileAsync",
        });
      });
};

export const editPhysicalPersonProfileAsync = (data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/customer/account/individual/`, data)
    .then((r) => {
      dispatch(setPhysicalPersonProfile(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "editPhysicalPersonProfileAsync",
      });
    });
};

export const {
  setCustomerAddressList,
  setCustomerAddress,
  setCompanyProfile,
  setPhysicalPersonProfile,
} = customerAccountSlice.actions;

export default customerAccountSlice.reducer;
