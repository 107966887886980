import React, { useEffect, useState } from "react";
import EventEmitter from "../../../utils/EventEmitter";
import { useDispatch, useSelector } from "react-redux";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { getServicesListAsync } from "../../../store/reducers/common/catalog";
import {
  createServiceAsync,
  editServiceByIdAsync,
} from "../../../store/reducers/dispatcher/catalog";
import {typeOfServPerPage, updatePagesPeriod} from "../../../utils/constants";
import { RouterPath } from "../../../utils/utils";

const TypesOfServices = () => {
  const currLocation = window.location.pathname;
  const dispatch = useDispatch();
  const headerList = useSelector((state) => state.tables.typeOfServicesHeaders);
  const { servicesList, servicesListCount } = useSelector(
    (state) => state.commonCatalog
  );

  const [itemsList, setItemList] = useState(servicesList);
  const [q, setQ] = useState("");
  const [pageData, setPageData] = useState(1);
  const [districtId, setDistrictId] = useState('');

  useEffect(() => {
    dispatch(
      getServicesListAsync({
        page: pageData,
        page_size: typeOfServPerPage,
        district: districtId,
        q,
      })
    );
  }, []);

  useEffect(() => {
    const updatePage = setInterval(() => {
      if (currLocation === RouterPath.typesOfServices) {
        dispatch(
          getServicesListAsync({
            page: pageData,
            page_size: typeOfServPerPage,
            district: districtId,
            q,
          })
        );
      }
    }, updatePagesPeriod);
    return () => clearInterval(updatePage);
  }, [currLocation, pageData, q, districtId]);

  useEffect(() => {
    setTimeout(() => {
      setItemList(servicesList);
    }, 0);
  }, [servicesList]);

  useEffect(() => {
    const getServicesList = (q) => {
      setQ(q);
      dispatch(
        getServicesListAsync({
          q: q,
          page: pageData,
          page_size: typeOfServPerPage,
          district: districtId
        })
      );
    };
    const addService = (eventData) => {
      setItemList((prevValue) => [...prevValue, eventData]);
      dispatch(createServiceAsync(eventData)).then(() => {
        setTimeout(() => {
          getServicesList();
        }, 500);
      });
    };
    const editService = (eventData) => {
      dispatch(editServiceByIdAsync(eventData.id, eventData)).then(() => {
        setTimeout(() => {
          getServicesList();
        }, 500);
      });
    };
    const addNewServiceListener = EventEmitter.addListener(
      "addNewService",
      addService
    );
    const editServiceListener = EventEmitter.addListener(
      "editService",
      editService
    );
    const searchListener = EventEmitter.addListener("search", getServicesList);
    const filterDistrictListener = EventEmitter.addListener('filterDistrict', (district) => {
      setDistrictId(district?.id)
      dispatch(getServicesListAsync({ page: pageData, page_size: typeOfServPerPage, district: district?.id }))
    })
    const pageListener = EventEmitter.addListener("page", (page) => {
      setPageData(page);
      dispatch(
        getServicesListAsync({ page, page_size: typeOfServPerPage, district: districtId })
      );
    });
    return () => {
      filterDistrictListener.remove();
      editServiceListener.remove();
      addNewServiceListener.remove();
      searchListener.remove();
      pageListener.remove();
    };
  });
  return (
    <AppMainTable
      title="Виды услуг"
      search="Поиск услуги"
      itemList={itemsList}
      itemListTotalCount={servicesListCount}
      headerList={headerList}
      mode="servTable"
    />
  );
};

export default TypesOfServices;
