import React, { useEffect, useLayoutEffect, useState } from "react";
import "./styles/UrModalAddd.scoped.scss";
import AppInput from "../../../layout/AppInput/AppInput";
import AppButton from "../../../layout/AppButton/AppButton";
import AppTextarea from "../../../layout/AppTextarea/AppTextarea";
import AppCheckbox from "../../../layout/AppCheckbox/AppCheckbox";
import EventEmitter from "../../../../utils/EventEmitter";
import AppPhoneInput from "../../../layout/AppPhoneInput/AppPhoneInput";
import { useDispatch } from "react-redux";
import { addError } from "../../../../store/reducers/alerts";
import { setIsClosable } from "../../../../store/reducers/modals";

const UrModalAdd = ({ closeModal, options }) => {
  const dispatch = useDispatch();

  const [modalTitle] = useState(setModalTitleData());
  const [actionButtonText] = useState(setActionButtonText());
  const [isIdenticalAddresses, setIdenticalAddresses] = useState(false);
  const [emailVal, setEmailVal] = useState(false);
  const [bankKorAccountVal, setBankKorAccountVal] = useState(false);
  const [bankBikVal, setBankBikVal] = useState(false);
  const [bankAccountVal, setBankAccountVal] = useState(false);
  const [bankNameVal, setBankNameVal] = useState(false);
  const [ogrnVal, setOgrnVal] = useState(false);
  const [kppVal, setKppVal] = useState(false);
  const [innVal, setInnVal] = useState(false);
  const [postAddressVal, setPostAddressVal] = useState(false);
  const [actualAddressVal, setActualAddressVal] = useState(false);
  const [legalAddressVal, setLegalAddressVal] = useState(false);
  const [actsOnBasisVal, setActsOnBasisVal] = useState(false);
  const [executiveAgencyVal, setExecutiveAgencyVal] = useState(false);
  const [phoneVal, setPhoneVal] = useState(false);
  const [ceoFioVal, setCeoFioVal] = useState(false);
  const [shortNameVal, setShortNameVal] = useState(false);
  const [fullNameVal, setFullNameVal] = useState(false);
  const [loginVal, setLoginVal] = useState(false);

  const [ur, setUr] = useState({
    id: options?.option?.id,
    username: options?.option?.username,
    email: options?.option?.email,
    type: options?.option?.type || "legal",
    ceo_fio: options?.option?.ceo_fio,
    phone: options?.option?.phone,
    inn: options?.option?.inn,
    kpp: options?.option?.kpp,
    bank_name: options?.option?.bank_name,
    bank_bik: options?.option?.bank_bik,
    bank_account: options?.option?.bank_account,
    bank_kor_account: options?.option?.bank_kor_account,
    full_name: options?.option?.full_name,
    short_name: options?.option?.short_name,
    legal_address: options?.option?.legal_address,
    actual_address: options?.option?.actual_address,
    post_address: options?.option?.post_address,
    ogrn: options?.option?.ogrn,
    executive_agency: options?.option?.executive_agency,
    acts_on_basis: options?.option?.acts_on_basis,
    active: options?.option?.active,
    meta: "existed",
  });
  useEffect(() => {
    dispatch(setIsClosable(false));
  }, []);

  function setModalTitleData() {
    if (options?.mode === "edit") return "Редактировать Юр.лицо";
    else return "Добавить Юр.лицо";
  }

  function setActionButtonText() {
    if (options?.mode === "edit") return "Редактировать Юр.лицо";
    else return "Добавить Юр.лицо";
  }

  function setIdenticalAddressesData() {
    setIdenticalAddresses((prevValue) => !prevValue);
    setUr({ ...ur, actual_address: ur?.legal_address });
  }

  const checkValidEmail = (isValid) => {
    setEmailVal(isValid);
  };

  const checkValidBankKorAccount = (isValid) => {
    setBankKorAccountVal(isValid);
  };

  const checkValidBankBik = (isValid) => {
    setBankBikVal(isValid);
  };

  const checkValidBankAccount = (isValid) => {
    setBankAccountVal(isValid);
  };

  const checkValidBankName = (isValid) => {
    setBankNameVal(isValid);
  };

  const checkValidOgrn = (isValid) => {
    setOgrnVal(isValid);
  };

  const checkValidKpp = (isValid) => {
    setKppVal(isValid);
  };

  const checkValidInn = (isValid) => {
    setInnVal(isValid);
  };

  const checkValidPostAddress = (isValid) => {
    setPostAddressVal(isValid);
  };

  const checkValidActualAddress = (isValid) => {
    setActualAddressVal(isValid);
  };

  const checkValidLegalAddress = (isValid) => {
    setLegalAddressVal(isValid);
  };

  const checkValidActsOnBasis = (isValid) => {
    setActsOnBasisVal(isValid);
  };

  const checkValidExecutiveAgency = (isValid) => {
    setExecutiveAgencyVal(isValid);
  };

  const checkValidPhone = (isValid) => {
    setPhoneVal(isValid);
  };

  const checkValidCeoFio = (isValid) => {
    setCeoFioVal(isValid);
  };

  const checkValidShortName = (isValid) => {
    setShortNameVal(isValid);
  };

  const checkValidFullName = (isValid) => {
    setFullNameVal(isValid);
  };
  const checkValidLogin = (isValid) => {
    setLoginVal(isValid);
  };

  const isDisabledActionButton = () => {
    return (
      !emailVal ||
      !bankKorAccountVal ||
      !bankBikVal ||
      !bankAccountVal ||
      !bankNameVal ||
      !ogrnVal ||
      !kppVal ||
      !innVal ||
      !postAddressVal ||
      !actualAddressVal ||
      !legalAddressVal ||
      !actsOnBasisVal ||
      !executiveAgencyVal ||
      !phoneVal ||
      !ceoFioVal ||
      !shortNameVal ||
      !fullNameVal
      // !loginVal
    );
  };

  function modalAction() {
    if (options?.mode === "edit")
      validate(() => EventEmitter.emit("editUr", { ...ur, type: "legal" }), ur);
    else
      validate(
        () => EventEmitter.emit("addNewUr", { ...ur, type: "legal" }),
        ur
      );
  }

  function validate(func, data) {
    const errors = [];
    // if (!data?.username?.length) errors.push('')
    if (!data?.email?.length) errors.push("Не указан email!");
    if (!data?.type?.length) errors.push("Не указан тип организации!");
    if (!data?.username?.length) errors.push("Не указан логин!");
    if (!data?.full_name?.length)
      errors.push("Не указано полное название организации!");
    if (!data?.ceo_fio?.length) errors.push("Не указано ФИО руководителя!");
    if (!data?.phone?.length) errors.push("Не указан номер телефона!");
    if (!data?.short_name?.length)
      errors.push("Не указано короткое название организации!");
    if (!data?.inn?.length) errors.push("Не указан ИНН!");
    if (!data?.legal_address?.length)
      errors.push("Не указан юридический адрес!");
    if (!data?.actual_address?.length)
      errors.push("Не указан фактический адрес!");
    if (!data?.post_address?.length) errors.push("Не указан почтовый адрес!");
    if (!data?.ogrn?.length) errors.push("Не указан ОГРН!");
    if (!data?.kpp?.length) errors.push("Не указан КПП!");
    if (!data?.executive_agency?.length)
      errors.push("Не указан единоличный исполнительный орган!");
    if (!data?.acts_on_basis?.length)
      errors.push("Не указано действует на основании!");
    if (!data?.bank_name?.length) errors.push("Не указано название банка!");
    if (!data?.bank_bik?.length) errors.push("Не указан БИК!");
    if (!data?.bank_account?.length) errors.push("Не указан расчетный счет!");
    if (!data?.bank_kor_account?.length)
      errors.push("Не указан корреспондентский счет!");
    if (!errors?.length) {
      func();
    }
    if (errors?.length) {
      for (const error of errors) dispatch(addError(error));
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (typeof options?.option === "object") {
        setUr({
          ...options?.option,
        });
      }
    }, 0);
  }, [options?.option]);

  useEffect(() => {
    setTimeout(() => {
      setUr({
        id: ur?.id,
        username: ur?.username,
        email: ur?.email,
        type: ur?.type,
        ceo_fio: ur?.ceo_fio,
        phone: ur?.phone,
        inn: ur?.inn,
        kpp: ur?.kpp,
        bank_name: ur?.bank_name,
        bank_bik: ur?.bank_bik,
        bank_account: ur?.bank_account,
        bank_kor_account: ur?.bank_kor_account,
        full_name: ur?.full_name,
        short_name: ur?.short_name,
        legal_address: ur?.legal_address,
        actual_address: ur?.actual_address,
        post_address: ur?.post_address,
        ogrn: ur?.ogrn,
        executive_agency: ur?.executive_agency,
        acts_on_basis: ur?.acts_on_basis,
        birth_date: ur?.birth_date,
        active: ur?.active,
      });
    }, 0);
  }, [
    ur?.acts_on_basis,
    ur?.actual_address,
    ur?.bank_account,
    ur?.bank_bik,
    ur?.bank_kor_account,
    ur?.bank_name,
    ur?.birth_date,
    ur?.ceo_fio,
    ur?.email,
    ur?.executive_agency,
    ur?.full_name,
    ur?.id,
    ur?.kpp,
    ur?.inn,
    ur?.legal_address,
    ur?.ogrn,
    ur?.phone,
    ur?.post_address,
    ur?.short_name,
    ur?.type,
    ur?.username,
    ur?.active,
  ]);

  return (
    <>
      <h1 className="stats-modal-name">{modalTitle}</h1>
      <div className="modal-wrapper">
        <div className="left-side">
          <h3 className="modal-title">Основная информация</h3>
          <div className="profile-block">
            <div className="input-ur-area">
              <AppInput
                className="modal-input"
                mode="white"
                label="Логин (латинскими буквами, цифрами)"
                placeholder="*****"
                value={ur?.username}
                onEnterActionFunc={() => modalAction()}
                validations={options?.mode === "edit" ? {} : { isEmpty: true }}
                checkValid={checkValidLogin}
                onInput={(e) => setUr({ ...ur, username: e })}
                isReq={true}
                maxLength={150}
                isDisabled={options?.mode === "edit"}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Полное наименование"
                placeholder="Указать"
                value={ur?.full_name}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidFullName}
                onInput={(e) => setUr({ ...ur, full_name: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Сокращенное наименование"
                placeholder="Указать"
                value={ur?.short_name}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidShortName}
                onInput={(e) => setUr({ ...ur, short_name: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="ФИО руководителя (представителя)"
                placeholder="Указать"
                value={ur?.ceo_fio}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidCeoFio}
                onInput={(e) => setUr({ ...ur, ceo_fio: e })}
                isReq={true}
              />
              <AppCheckbox
                label="Активно?"
                onChange={(e) => setUr({ ...ur, active: e })}
                checked={ur?.active}
              />
            </div>
          </div>
          <h3 className="modal-title">Адрес</h3>
          <div className="profile-block">
            <div className="input-ur-area">
              <AppTextarea
                mode="white"
                label="Юридический адрес"
                placeholder="Указать"
                rows={3}
                value={ur?.legal_address}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidLegalAddress}
                onInput={(e) => setUr({ ...ur, legal_address: e })}
                isReq={true}
              />
              <div className="address-check">
                <div className="check-text">Совпадает с физ. адресом</div>
                <AppCheckbox
                  mode="blue"
                  onChange={() => setIdenticalAddressesData()}
                  value={!!isIdenticalAddresses}
                />
              </div>
              <AppTextarea
                mode="white"
                label="Фактический адрес"
                placeholder="Указать"
                rows={3}
                value={ur?.actual_address}
                validations={{ isEmpty: true }}
                onEnterActionFunc={() => modalAction()}
                checkValid={checkValidActualAddress}
                isDisabled={!!isIdenticalAddresses}
                onInput={(e) => setUr({ ...ur, actual_address: e })}
                isReq={true}
              />
              <AppTextarea
                mode="white"
                label="Почтовый адрес"
                placeholder="Указать"
                rows={3}
                value={ur?.post_address}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidPostAddress}
                onInput={(e) => setUr({ ...ur, post_address: e })}
                isReq={true}
              />
            </div>
          </div>
        </div>
        <div className="left-side">
          <div className="profile-right-block">
            <AppPhoneInput
              className="modal-input"
              mode="white"
              label="Номер телефона"
              placeholder="Указать"
              value={ur?.phone}
              onEnterActionFunc={() => modalAction()}
              checkValid={checkValidPhone}
              validations={{ isEmpty: true, minLength: 18 }}
              onInput={(e) => setUr({ ...ur, phone: e })}
              isReq={true}
            />
            <AppInput
              className="modal-input"
              mode="white"
              label="E-mail"
              placeholder="Указать"
              value={ur?.email}
              onEnterActionFunc={() => modalAction()}
              checkValid={checkValidEmail}
              validations={{ isEmpty: true, isEmail: true }}
              onInput={(e) => setUr({ ...ur, email: e })}
              isReq={true}
              maxLength={253}
            />
            <AppInput
              className="modal-input"
              mode="white"
              label="Единоличный исполнительный орган"
              placeholder="Указать"
              value={ur?.executive_agency}
              onEnterActionFunc={() => modalAction()}
              validations={{ isEmpty: true }}
              checkValid={checkValidExecutiveAgency}
              onInput={(e) => setUr({ ...ur, executive_agency: e })}
              isReq={true}
            />
            <AppTextarea
              mode="white"
              label="Действует на основании (устав, доверенность)"
              placeholder="Указать"
              rows={2}
              value={ur?.acts_on_basis}
              onEnterActionFunc={() => modalAction()}
              validations={{ isEmpty: true }}
              checkValid={checkValidActsOnBasis}
              onInput={(e) => setUr({ ...ur, acts_on_basis: e })}
              isReq={true}
            />
          </div>
          <h3 className="modal-title">Бухгалтерская информация</h3>
          <div className="profile-block">
            <div className="input-ur-area">
              <AppInput
                className="modal-input"
                mode="white"
                label="ИНН"
                placeholder="Указать"
                maxLength={10}
                value={ur?.inn}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 10 }}
                checkValid={checkValidInn}
                onInput={(e) => setUr({ ...ur, inn: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="КПП"
                maxLength={9}
                placeholder="Указать"
                value={ur?.kpp}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 9 }}
                checkValid={checkValidKpp}
                onInput={(e) => setUr({ ...ur, kpp: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="ОГРН"
                placeholder="Указать"
                value={ur?.ogrn}
                maxLength={13}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 13 }}
                checkValid={checkValidOgrn}
                onInput={(e) => setUr({ ...ur, ogrn: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Наименование банка"
                placeholder="Указать"
                value={ur?.bank_name}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true }}
                checkValid={checkValidBankName}
                onInput={(e) => setUr({ ...ur, bank_name: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="БИК"
                placeholder="Указать"
                maxLength={9}
                value={ur?.bank_bik}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 9 }}
                checkValid={checkValidBankBik}
                onInput={(e) => setUr({ ...ur, bank_bik: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Расчётный счёт"
                placeholder="Указать"
                maxLength={20}
                value={ur?.bank_account}
                onEnterActionFunc={() => modalAction()}
                validations={{ isEmpty: true, minLength: 20 }}
                checkValid={checkValidBankAccount}
                onInput={(e) => setUr({ ...ur, bank_account: e })}
                isReq={true}
              />
              <AppInput
                className="modal-input"
                mode="white"
                label="Корреспондентский счёт"
                placeholder="Указать"
                value={ur?.bank_kor_account}
                maxLength={20}
                onEnterActionFunc={() => modalAction()}
                checkValid={checkValidBankKorAccount}
                validations={{ isEmpty: true, minLength: 20 }}
                onInput={(e) => setUr({ ...ur, bank_kor_account: e })}
                isReq={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-bottom">
        <AppButton
          mode="blue"
          onClick={modalAction}
          isDisabled={isDisabledActionButton()}
        >
          {actionButtonText}
        </AppButton>
        <AppButton onClick={() => closeModal()}>Выйти</AppButton>
      </div>
    </>
  );
};

export default UrModalAdd;
