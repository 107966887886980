import React, {useEffect, useState} from "react";
import "./styles/Auth.scoped.scss";
import AppInput from "../../layout/AppInput/AppInput";
import AppButton from "../../layout/AppButton/AppButton";
import { useDispatch } from "react-redux";
import {signInAsync, verifyTokenAsync} from "../../../store/reducers/auth";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "../../../utils/utils";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVal, setPasswordVal] = useState(false);
  const [isLoginVal, setLoginVal] = useState(false);
  function logIn() {
    dispatch(signInAsync({ username: login, password: password })).then(() => {
      if (!!localStorage.getItem("access")?.length) navigate(RouterPath.empList);
    });
  }

  const checkValidLogin = (isValid) => {
    setLoginVal(isValid)
  }

  const checkValidPass = (isValid) => {
    setPasswordVal(isValid)
  }

  useEffect(() => {}, [isLoginVal, isPasswordVal])

  useEffect(() => {
    if (!!localStorage.getItem("access")?.length) {
      dispatch(verifyTokenAsync({ token: localStorage.getItem("access") })).then(() => {
        navigate(RouterPath.empList);
      })
    }
  })

  return (
    <div className="auth">
      <div className="auth__form form">
        <h2 className="form__title">Добро пожаловать</h2>
        <AppInput
          className="auth-input"
          label="Логин"
          mode="default"
          type='email'
          placeholder="Введите логин"
          checkValid={checkValidLogin}
          validations={{ isEmpty: true }}
          value={login}
          autoComplete={true}
          onEnterActionFunc={() => logIn()}
          onInput={(e) => setLogin(e)}
        />
        <AppInput
          className="auth-input"
          type="password"
          label="Пароль"
          mode="default"
          placeholder="Введите пароль"
          checkValid={checkValidPass}
          value={password}
          autoComplete={true}
          onEnterActionFunc={() => logIn()}
          validations={{ isEmpty: true, minLength: 8}}
          onInput={(e) => setPassword(e)}
        />
        <AppButton
            className="auth-btn"
            isDisabled={!isPasswordVal || !isLoginVal}
            onClick={() => logIn()}
        >
          <span className="form__btn-text">Войти</span>
        </AppButton>
      </div>
    </div>
  );
};

export default Auth;
