import React from "react";
import '../../styles/ServAddModal.scoped.scss'
import AppButton from "../../../../layout/AppButton/AppButton";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../store/reducers/modals";
import { modalKeys } from "../../../../../utils/utils";
import PropTypes from "prop-types";

const ChoiceServ = ({ options, closeModal }) => {
  const dispatch = useDispatch();

  function moveToServ() {
    dispatch(showModal({ key: modalKeys.servAddModal }));
  }


  return (
    <>
      <div className="choice-area">
        <h1 className="modal-name">Выберите действие</h1>
        <AppButton className="choice-operator" onClick={() => moveToServ()}>
          Добавить услугу
        </AppButton>
      </div>
    </>
  );
};

ChoiceServ.defaultProps = {
  closeModal: () => {},
  options: {},
};

ChoiceServ.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ChoiceServ;
