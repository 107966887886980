import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { APIUrlPrefix } from "../../../utils/constants";
import { addSuccess } from "../alerts";
import {errorHandler} from "../../../scripts/commonScripts";

const commonCatalogSlice = createSlice({
  name: "commonCatalog",
  initialState: {
    servicesList: [],
    dictionaries: [],
    servicesListCount: 0,
    districtList: [],
    district: {},
    tariffPrice: { price: 0 },
    districtListCount: 0
  },
  reducers: {
    setServicesList: (state, action) => {
      state.servicesList = [...action.payload];
    },
    setDictionaries: (state, action) => {
      state.dictionaries = action.payload;
      /*localStorage.setItem('ip-id', action.payload?.content_types?.individualcustomer)*/
      localStorage.setItem('ip-id', action.payload?.content_types?.companycustomer)
      localStorage.setItem('legal-id', action.payload?.content_types?.companycustomer)
    },
    setServicesListCount: (state, action) => {
      state.servicesListCount = action.payload;
    },
    setDistrictList: (state, action) => {
      state.districtList = [...action.payload];
    },
    setDistrictListCount: (state, action) => {
      state.districtListCount = action.payload;
    },
    setDistrict: (state, action) => {
      state.district = action.payload;
    },
    setTariffPrice: (state, action) => {
      state.tariffPrice = action.payload;
    }
  },
});

export const getDictionariesAsync = () => async (dispatch) => {
  await api.get(`${APIUrlPrefix}/common/catalog/dictionaries/`).then((r) => {
    dispatch(setDictionaries(r?.data));
  }).catch((e) => {
    errorHandler({
      error: e,
      dispatch,
      text: "",
      requestName: "getDictionariesAsync",
    });
  });
};

export const getServicesListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/common/catalog/service/`, { params: params })
    .then((r) => {
      dispatch(setServicesList(r?.data?.results));
      dispatch(setServicesListCount(r?.data?.count));
    }).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getServicesListAsync",
        });
      });
};
/////////////////////////
export const getDistrictListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/common/catalog/district/`, { params: params })
    .then((r) => {
      dispatch(setDistrictList(r?.data?.results));
      dispatch(setDistrictListCount(r?.data?.count));
    }).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDistrictListAsync",
        });
      });
};

export const createDistrictAsync = (params) => async (dispatch) => {
  await api.post(`${APIUrlPrefix}/common/catalog/district/`, {
    params: params,
  }).catch((e) => {
    errorHandler({
      error: e,
      dispatch,
      text: "",
      requestName: "createDistrictAsync",
    });
  });
};

export const getDistrictByIdAsync = (id) => async (dispatch) => {
  await api.get(`${APIUrlPrefix}/common/catalog/district/${id}`).then((r) => {
    dispatch(setDistrict(r?.data));
  }).catch((e) => {
    errorHandler({
      error: e,
      dispatch,
      text: "",
      requestName: "getDistrictByIdAsync",
    });
  });
};

export const editDistrictByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/catalog/district/${id}/`, data)
    .then(() => {
      dispatch(addSuccess("Район успешно отредактирован!"));
    }).catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "editDistrictByIdAsync",
        });
      });
};

export const getTariffPriceAsync =
  (serviceId, districtId) => async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/common/catalog/tariff/`, {
        params: { serviceId, districtId },
      })
      .then((r) => {
        dispatch(setTariffPrice(r?.data));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getTariffPriceAsync",
        });
      });
  };

export const {
  setServicesList,
  setDictionaries,
  setServicesListCount,
  setDistrictList,
  setDistrict,
  setTariffPrice,
  setDistrictListCount
} = commonCatalogSlice.actions;
export default commonCatalogSlice.reducer;
