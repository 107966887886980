import React, { useEffect, useState } from "react";
import "./styles/AppSelect.scoped.scss";
import classNames from "classnames";
import SvgSelector from "../SvgSelector/SvgSelector";
import PropTypes from "prop-types";
import useComponentVisible from "../../../scripts/hooks";

const AppSelect = ({
                       selected,
                       defaultText,
                       optionsList,
                       onInput,
                       label,
                       mode,
                       isRequired,
                       isRequiredText,
                       setOptionObj,
                       checkValid,
                       isDisabled,
                       isOnInputType,
                       isReq,
                   }) => {
    const [defaultSelectText, setDefaultSelectText] = useState(defaultText);
    let [showOptionList, setShowOptionList] = useState(false);
    const [valid, setValid] = useState(false);
    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const containerStyles = classNames(
        "custom-select-container",
        containerStyleModes()
    );
    const selectOptionStyles = classNames(
        "select-option",
        selectOptionStyleModes()
    );
    const selectedTextStyles = classNames(
        "selected-text",
        selectedTextStylesIsDisabled()
    );
    const arrowStyles = classNames("select-arrow", flipArrow());
    const selectItemsStyles = classNames("select-options", openSelectStyles());

    useEffect(() => {
        if (!!selected?.name || !!selected?.full_name || !!selected?.address)
            setDefaultSelectText(selected?.name || selected?.full_name || selected?.address);

        if (label === "Услуга" && selected === 0)
            setDefaultSelectText("Категория")

        if (label === "Плательщик")
            setDefaultSelectText(selected?.ceo_fio || "Выберите статус")
    }, [selected]);

    useEffect(() => {
        if (isRequired && (selected?.name || selected?.full_name || selected?.address)) setValid(true);
        if (isRequired && (!selected?.name || !selected?.full_name || !selected?.address)) setValid(false);
    }, [selected?.name, selected?.address, selected?.full_name]);

    useEffect(() => {
        checkValid(valid);
    });

    function handleListDisplay() {
        if (!!optionsList?.length) {
            if (!isDisabled) {
                setShowOptionList((prevState) => !prevState);
                setIsComponentVisible(isComponentVisible = !isComponentVisible);
            }
        } else {
            console.log("Не тот тип данных!, Сейчас:", typeof optionsList);
        }
    }

    function handleOptionClick(e) {
        setOptionObj(e);
        onInput(isOnInputType ? e?.type || e?.id : e?.id || e?.type);
        setIsComponentVisible(false);

        if(label === "Статус" && isOnInputType) {
            onInput(e?.name)
        }
        if (label === "Плательщик") {
            setDefaultSelectText(e?.ceo_fio);
        } else
            setDefaultSelectText(
                /*e?.name || e?.full_name || e?.short_name || e?.ceo_fio || e?.address*/
                e?.name || e?.full_name || e?.short_name || e?.address
            );
    }

    function flipArrow() {
        if (isComponentVisible) return "select-arrow-up";
    }

    function selectOptionStyleModes() {
        const modes = {
            grey: "select-option_grey",
            white: "select-option_white",
        };
        return modes[mode];
    }

    function containerStyleModes() {
        const modes = {
            grey: "custom-select-container_grey",
            white: "custom-select-container_white",
        };
        if (isComponentVisible) return "custom-select-container__opened";
        return modes[mode];
    }

    function selectedTextStylesIsDisabled() {
        if (isDisabled) return "selected-text_disabled";
    }

    function openSelectStyles() {
        if (isComponentVisible) {
            return "select-options__opened";
        }
    }

    function isNeed(req) {
        if (isReq) return "req";
        return "no-req"
    }


    return (
        <>
            <div className="app-select" ref={ref}>
                <div className="label-area">
                    <label className="app-select__label">{label}</label>
                    <label className={isNeed()}>*</label>
                </div>
                <div className={containerStyles}>
                    <div className={selectedTextStyles} onClick={handleListDisplay}>
                        <div className="selected-text-text">{defaultSelectText}</div>
                        <SvgSelector className={arrowStyles} id="select-arrow-down"/>
                    </div>
                    <ul className={selectItemsStyles}>
                        {!!optionsList?.length && isComponentVisible &&
                            optionsList?.map((option, idx) => {
                                return (
                                    <li
                                        className={selectOptionStyles}
                                        data-name={
                                            option?.name ||
                                            option?.full_name ||
                                            option?.short_name ||
                                            option?.ceo_fio ||
                                            option?.address
                                        }
                                        key={idx}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {label === "Плательщик"
                                            ? option?.ceo_fio
                                            : option?.name ||
                                            option?.full_name ||
                                            option?.short_name ||
                                            option?.address
                                        }
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {!showOptionList && isRequired && !valid && (
                    <div className="app-select__error-text">{isRequiredText}</div>
                )}
            </div>
        </>
    );
};

AppSelect.propTypes = {
    selected: PropTypes.object,
    onInput: PropTypes.func,
    defaultText: PropTypes.string,
    optionsList: PropTypes.array.isRequired,
    label: PropTypes.string,
    mode: PropTypes.string,
    isRequired: PropTypes.bool,
    isReq: PropTypes.bool,
    checkValid: PropTypes.func,
    isRequiredText: PropTypes.string,
    setOptionObj: PropTypes.func,
    isDisabled: PropTypes.bool,
    isShowDefaultText: PropTypes.bool,
    isOnInputType: PropTypes.bool,
};

AppSelect.defaultProps = {
    defaultText: "Укажите значение",
    isOnInputType: true,
    isShowDefaultText: false,
    isDisabled: false,
    isRequired: false,
    isReq: false,
    onInput: () => {
    },
    checkValid: () => {
    },
    isRequiredText: "Это поле обязательно для заполнения!",
    setOptionObj: () => {
    },
};

export default AppSelect;
