import { empListFilters, RouterPath, urListFilters } from "../utils/utils";
import EventEmitter from "../utils/EventEmitter";

export function defaultFilter(filterData, currLocation, profile) {
  const isAdmin = localStorage.getItem('isAdmin')
  if (currLocation === RouterPath.empList) {
    if (isAdmin) return localStorage.getItem("empListFilter") === empListFilters.executors ? filterData.empListAdmin[0] : filterData.empListAdmin[1]
    else return filterData.empListAdmin[0]
  }
  if (currLocation === RouterPath.urList) {
    return localStorage.getItem("urListFilter") === urListFilters.urs ? filterData.urList[0] : filterData.urList[1]
  }
  if (currLocation === RouterPath.apps) {
    return filterData.application[0]
  }
  if (currLocation === RouterPath.account) {
    return filterData.account[0]
  }
}

export function UrListType(type) {
  const types = {
    legal: "Юр.лицо",
    ip: "ИП",
  };
  return types[type];
}

export function accountPayMeasureConvert (measure) {
  const measures = {
    "time": "часов",
    "weight": 'тонн',
    "volume": "м3"
  }
  return measures[measure]
}

export function filtersSearchBar(currLocation, profile, filterData) {
  const isAdmin = profile?.is_admin
  if (currLocation === RouterPath.empList) {
    if (!!isAdmin) return filterData.empListAdmin;
    if (!isAdmin) return filterData.empListNotAdmin;
  }
  if (currLocation === RouterPath.apps) return filterData.application;
  if (currLocation === RouterPath.account) return filterData.account;
  if (currLocation === RouterPath.typesOfServices) return [];
  if (currLocation === RouterPath.urList) return filterData.urList;
}

export function filterActionSearchBar(
  option,
  setCurrentFilter,
  currLocation,
  searchTermDispatcher,
  searchTermExecutor,
  searchTermUr,
  searchTermApps
) {
  setCurrentFilter(option);
  if (currLocation === RouterPath.empList) {
    localStorage.setItem("empListFilter", option);
    if (option === empListFilters.dispatchers) {
      EventEmitter.emit("search", searchTermDispatcher);
      EventEmitter.emit("dataTermDispatcher", "");
    }
    if (option === empListFilters.executors) {
      EventEmitter.emit("search", searchTermExecutor);
      EventEmitter.emit("dataTermExecutor", "");
    }
  }
  if (currLocation === RouterPath.urList) {
    localStorage.setItem("urListFilter", option);
    if (option === urListFilters.urs) {
      EventEmitter.emit("search", searchTermUr);
      EventEmitter.emit("urListFiltersType", { type: "legal" });
    }
    if (option === urListFilters.ips) {
      EventEmitter.emit("search", searchTermUr);
      EventEmitter.emit("urListFiltersType", { type: "ip" });
    }
  }
  if (currLocation === RouterPath.account) {
    if (option === "Физ.лица") {
      EventEmitter.emit("accountCustomerType", { customer_type: localStorage.getItem('ip-id') });
    }
    if (option === "Предприятия") {
      EventEmitter.emit("accountCustomerType", { customer_type: localStorage.getItem('legal-id') });
    }
  }
  // if (currLocation === RouterPath.apps) {
  //   localStorage.setItem("appsFilter", option);
  //   if (option === "Все") {
  //     EventEmitter.emit("AppsFiltersStatus", { status: "" });
  //   }
  //   if (option === "Ожидает подтверждения") {
  //     EventEmitter.emit("AppsFiltersStatus", { status: "wait_approve" });
  //   }
  //   if (option === "В работе") {
  //     EventEmitter.emit("AppsFiltersStatus", { status: "in_progress" });
  //   }
  //   if (option === "Завершено") {
  //     EventEmitter.emit("AppsFiltersStatus", { status: "finished" });
  //   }
  //   if (option === "Отклонено") {
  //     EventEmitter.emit("AppsFiltersStatus", { status: "canceled" });
  //   }
  // }
}

export function servicesSearchById ({ services, servicesList, setterFunc }) {
  const serviceArr = [];
  if (!!services?.length && !!servicesList?.length) {
    for (const serviceId of services) {
      const service = servicesList.filter(
        (service) => service.id === serviceId
      );
      serviceArr.push(...service);
    }
  }
  setterFunc(serviceArr);
}

export const servicesCategoryHuman = (type) => {
  const types = {
    water: "Вода",
    waste: "Отходы",
    machinery: "Транспорт",
    cargo: "Доставка",
  };

  return types[type];
};
