import { createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { saveTokensToLocalStorage } from "../../scripts/auth";
import { addSuccess } from "./alerts";
import { APIUrlPrefix } from "../../utils/constants";
import { errorHandler, updateTokens } from "../../scripts/commonScripts";

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
});

export const signInAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/auth/web/sign-in/`, data)
    .then((r) => {
      dispatch(addSuccess("Вход успешно выполнен!"));
      saveTokensToLocalStorage(r?.data);
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "signInAsync",
      });
    });
};

export const refreshTokensAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/auth/token/refresh/`, data)
    .then((r) => {
      saveTokensToLocalStorage(r?.data);
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "refreshTokensAsync",
      });
    });
};

export const verifyTokenAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/auth/token/verify/`, data)
    .catch((e) => {
      if (e.response?.data?.code === "token_not_valid") {
        updateTokens(e, dispatch, "getDispatcherProfileAsync");
      }
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "verifyTokenAsync",
      });
    });
};

export default authSlice.reducer;
