import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { APIUrlPrefix } from "../../../utils/constants";
import { addSuccess } from "../alerts";
import { errorHandler } from "../../../scripts/commonScripts";

const paymentSlice = createSlice({
  name: "dispatcherPayment",
  initialState: {
    dispatcherPaymentBillList: [],
    dispatcherPaymentBillListCount: 0,
    dispatcherPaymentBill: {},
  },
  reducers: {
    setDispatcherPaymentBillList: (state, action) => {
      state.dispatcherPaymentBillList = [...action.payload];
    },
    setDispatcherPaymentBillListCount: (state, action) => {
      state.dispatcherPaymentBillListCount = action.payload;
    },
    setDispatcherPaymentBill: (state, action) => {
      state.dispatcherPaymentBill = action.payload;
    },
  },
});

export const getDispatcherPaymentBillListAsync =
  (params) => async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/dispatcher/payment/bill/`, { params: params })
      .then((r) => {
        dispatch(setDispatcherPaymentBillList(r?.data?.results));
        dispatch(setDispatcherPaymentBillListCount(r?.data?.count));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDispatcherPaymentBillListAsync",
        });
      });
  };

export const getDispatcherPaymentBillByIdAsync =
  (billId) => async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/dispatcher/payment/bill/${billId}`)
      .then((r) => {
        dispatch(setDispatcherPaymentBill(r?.data));
      })
        .catch((e) => {
          errorHandler({
            error: e,
            dispatch,
            text: "",
            requestName: "getDispatcherPaymentBillByIdAsync",
          });
        });
  };

export const editDispatcherPaymentBillByIdAsync =
  (billId, data) => async (dispatch) => {
    await api
      .patch(`${APIUrlPrefix}/dispatcher/payment/bill/${billId}`, data)
      .then(() => {
        dispatch(addSuccess("Счет успешно изменен!"));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "Ошибка редактирования счета!",
          requestName: "editDispatcherPaymentBillByIdAsync",
        });
      });
  };

export const {
  setDispatcherPaymentBillList,
  setDispatcherPaymentBillListCount,
  setDispatcherPaymentBill,
} = paymentSlice.actions;
export default paymentSlice.reducer;
